import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import React from 'react';

type KeyValuesProps = {
    key: string;
    value: string;
};

const formatExtraData = (option: any): string | undefined => {
    const { optionExtraKeyValues = [] } = option ?? {};

    if (!optionExtraKeyValues.length) return undefined;

    return optionExtraKeyValues
        .filter((item: KeyValuesProps) => item.value)
        .map((item: KeyValuesProps) => `${item.key}: ${item.value}`)
        .join(' | ');
};

const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    isParentTenant: boolean
) => {
    const { label } = option;
    const additionalInfo = formatExtraData(option);

    return (
        <li {...props}>
            <Stack direction="column" spacing={0.2}>
                <Typography variant="h6">{label}</Typography>
                {additionalInfo && isParentTenant && (
                    <Typography variant="xs" fontStyle="italic" fontSize="1.2rem">
                        {additionalInfo}
                    </Typography>
                )}
            </Stack>
        </li>
    );
};

export default renderOption;
