import { formatDocumentNumber } from 'helpers';
import { string, object, InferType } from 'yup';

export const validationSchemaUpdateMarginZetra = object().shape({
    registrationNumber: string()
        .typeError('O CPF informado é inválido.')
        .optional()
        .cpfCnpjValidation('CPF inválido.'),
});

export type InferTypeUpdateMarginZetra = InferType<typeof validationSchemaUpdateMarginZetra>;

export const defaultValuesMarginZetra = (registrationNumber: string) => {
    return {
        registrationNumber: formatDocumentNumber(registrationNumber),
    };
};
