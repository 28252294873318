/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Stack } from '@mui/material'
import { Typography } from '@uy3/web-components';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { SelectFormField, SelectOption, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { SwitchFormField } from 'components/Forms/FormFields/SwitchFormField/SwitchFormField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { useCreditProductData } from 'contexts/creditProductContext';
import { useFormContext } from 'contexts/formContext';
import { PersonFull } from 'contexts/personContext';
import { useRelatedBankAccountData } from 'contexts/relatedBankAccountContext';
import { isValidString } from 'helpers/formats/StringFormats';
import { useEffect } from 'react';
import { phoneNumberMask } from 'utils/constants';

type ChatFormProps = {
    productList: AutocompleteOptionList;
    serviceQueueList: AutocompleteOptionList;
    tenatnList: AutocompleteOptionList;
    personList: AutocompleteOptionList;
    getPersonByDiscriminator: (personId: string, discriminator: string) => void
}

export const ChatForm = ({ productList, serviceQueueList, personList, getPersonByDiscriminator }: ChatFormProps) => {

    const { watch, setValue} = useFormContext();
    const beneficiaryId = watch('beneficiaryId');
    const productId = watch('productId');
    const beneficiaryDiscriminator = watch('beneficiaryDiscriminator');
    const isLegalPerson = String(beneficiaryDiscriminator??"")?.toLowerCase() === 'legalPerson'

    const { data: beneficiaryBankList } = useRelatedBankAccountData(beneficiaryId!, isLegalPerson ? "LegalPerson" : "NaturalPerson");

    const { creditProductData, creditProductLoading } = useCreditProductData(productId!);

    useEffect(() => {
        if (!!productId && !!creditProductData)
            setValue('product', creditProductData);
    }, [creditProductData]); 

    const beneficiaryList = (creditProductData?.relatedPerson ?? [])
        ?.filter(person =>
            person.typeOfRelationship.toLowerCase() === 'beneficiary')
        ?.map(person => {
            return {
                label: person.personIdDisplay,
                value: person.personId,
                discriminator: person?.discriminator
            }
        }) as SelectOption[];

    useEffect(() => {
        if (!isValidString(String(beneficiaryId))) {
            setValue('beneficiaryId', null);
            setValue('bankAccountId', null);
            setValue('beneficiary.bankAccountId', null);
            setValue('beneficiary.bankAccountName', null);
        }
    }, [beneficiaryId]);

    return (
        <Stack mb={5} spacing={2} mr={2}>
            <Box p={0} mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Informações do chatbot
                </Typography>

                <Grid container spacing={3} columns={6}>
                    <Grid item xs={3}>
                        <TextFormField
                            name="botPhone"
                            variant="outlined"
                            fullWidth
                            label="Telefone do chatbot"
                            placeholder="(00) 00000-0000"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: phoneNumberMask },
                            }}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextFormField
                            name="companyName"
                            label="Nome do parceiro"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextFormField
                            name="originCampaign"
                            label="Origem da campanha"
                            required
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <AutocompleteField
                            name="serviceQueueId"
                            label="Fila de atendimento"
                            displayName="serviceQueueDisplay"
                            required
                            {...serviceQueueList}
                        />
                    </Grid>
                </Grid>

                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Informações de entrada
                </Typography>

                <Grid container spacing={3} columns={6}>
                    <Grid item xs={3}>
                        <AutocompleteField
                            name="productId"
                            label="Produto"
                            displayName="productName"
                            endIconType="link"
                            link={`/cadastro/produtos-credito/${watch("productId")}`}
                            showEndIcon={watch("productId")}
                            required
                            {...productList}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <CurrencyFormField
                            name="invoiceValue"
                            label="Valor"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <AutocompleteField
                            name="personId"
                            label="Tomador"
                            displayName="personName"
                            onChange={(a, b) => {
                                let data = b as any;
                                setValue('person', {
                                    personId: data?.value,
                                    registrationNumber: data?.registrationNumber,
                                    name: data?.name
                                })
                            }}
                            {...personList!}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextFormField
                            name="incomingMessage"
                            label="Escreva uma mensagem"
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <AutocompleteField
                            name="beneficiaryId"
                            label="Beneficiário"
                            displayName="beneficiaryName"
                            onChange={async (a, b) => {
                                var person = b as unknown as { value: string }& PersonFull;
                                const data = await getPersonByDiscriminator((person?.value!) as string, person?.discriminator!);
                                const personSelected = data as unknown as PersonFull;
                                if (data !== undefined && data !== null) {
                                    setValue('beneficiary', {
                                        personId: personSelected?.id,
                                        registrationNumber: personSelected?.registrationNumber,
                                        name: personSelected?.name
                                    });
                                }
                            }}
                            listOptions={beneficiaryList ?? []}
                            loading={creditProductLoading && !!productId}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <SelectFormField
                            name="bankAccountId"
                            label="Conta do beneficiário"
                            variant="outlined"
                            options={beneficiaryBankList?.relatedBankAccountData ?? []}
                            fullWidth
                        />
                    </Grid>

                </Grid>

                <Grid item xs={3} mt={4}>
                    <SwitchFormField name='isDefault' label='Definir como padrão' />
                </Grid>
            </Box>
        </Stack>
    )
}
