import { IconButton, Stack } from '@mui/material';
import {
    Button,
    CloseIcon,
    SaveIcon,
    DeleteIcon,
    AddIcon,
} from '@uy3/web-components';
import { ApiResponseError, ToastType, iconSx, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { FieldValues, useFieldArray } from 'react-hook-form';
import { CommissionFormCostsAccordionMetadataKey } from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import { ReserveToken } from './ReserveToken';
import { useDatasetReserveToken } from 'contexts/naturalPersonContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { validationSchemaReserveTokenForm, defaultValuesReserveTokenForm, ReserveTokenFormValues } from './ReserveTokenSchema';
import { RefreshProgress } from 'components/RefreshProgress';
import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';

type ReserveTokenContainerType = {
    onClose: () => void
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
    personId?: string;
    onChange?: (values: FieldValues[]) => void;
}

type TokenFormProps = {
    fields: Record<"id", string>[];
    remove: (index: number) => void;
};

const TokenForm = ({ fields, remove }: TokenFormProps) => (
    <>
        <Stack direction="column" spacing={2}>
            {fields.map((item, index) => (
                <Stack key={item.id} direction="row" alignItems="center">
                    <ReserveToken key={item.id} index={index} />
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ))}
        </Stack>
    </>
);

const theme = activeTheme();

export const ReserveTokenContainer = ({ onClose, setToast, personId, onChange }: ReserveTokenContainerType) => {
    const onSuccess = () => {
        showSuccessToast("Token consignado adicionado com sucesso", "", setToast)
        onClose();
    }

    const onError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        showErrorToast("Ops, ocorreu um erro", errorMessage, setToast)
    }

    const { mutate, isLoading } = useDatasetReserveToken(personId, onSuccess, onError);

    const handleSubmit = (values: FieldValues) => {
        const valuesList = values?.details as ReserveTokenFormValues[];
        const optionsListTokenMapped = valuesList?.map((item) => {
            return {
                warrantyRegistrationOffice: item?.warrantyRegistrationOffice,
                expirationDate: item?.expirationDate,
                warrantyCode: item.warrantyCode,
                token: {
                    tokenType: item?.tokenType,
                    tokenValue: item?.tokenValue
                }
            }
        });

        if (!onChange) mutate(optionsListTokenMapped);
        if (onChange) onChange(optionsListTokenMapped);
    }


    return (
        <>
            <FormProvider
                defaultValues={defaultValuesReserveTokenForm}
                validationSchema={validationSchemaReserveTokenForm()}
                onSubmit={handleSubmit}
                children={<ReserveTokenComponent onClose={onClose} isLoading={isLoading} />}
            />
        </>
    )
}

export const ReserveTokenComponent = ({ onClose, isLoading }: {
    onClose: () => void,
    isLoading: boolean
}) => {
    const { control, setValue, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'details',
    });

    const currentAccordion = watch(CommissionFormCostsAccordionMetadataKey);

    const removeComm = (index: number) => {
        if (index < currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, currentAccordion - 1);
        }
        if (index === currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, null);
        }
        remove(index);
    };

    const addNewComm = () => {
        append({
            expirationDate: toIsoStringWithTimezone(new Date(new Date().setHours(0, 0, 0, 0)))
        });
        setValue(CommissionFormCostsAccordionMetadataKey, fields.length);
    };

    return (
        <Stack spacing={4}>
            {isLoading && <RefreshProgress refreshing={isLoading} />}
            <TokenForm fields={fields} remove={removeComm} />
            <Button
                sx={{
                    justifyContent: 'flex-start',
                    fontWeight: 600,
                    fontSize: '15px',
                }}
                variant="text"
                onClick={addNewComm}
                startIcon={<AddIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
            >
                Adicionar
            </Button>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                >
                    Fechar
                </Button>
                <Button
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    variant="contained"
                    size="medium"
                    disabled={isLoading}
                    type="submit"
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    );
};
