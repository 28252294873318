import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { ISiapeMarginQueryParams } from "./datasets.interface";

var url = `Dataset`;
export const postDataset = async (recordType: string, id: string, dataset: string, token?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(`/${recordType}/${id}/${url}/${dataset}`, {}, config);
};

export const getDataset = async (recordType: string, id: string, dataset: string, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let datasetType = undefined;
    if (dataset) {
        datasetType = `/${dataset}`
    }
    return await creditAxiosApi.get(`/${recordType}/${id}/${url}${datasetType}`, config);
};

export const getDatasetOnlyTokensByPersonID = async (personId: string, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            getOnlyMostRecent: false
        }
    };
    return await creditAxiosApi.get(`/NaturalPerson/${personId}/dataset/ReserveToken`, config);
};

export const getDatasetAvailableReserveToken = async (personId: string, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.get(`/NaturalPerson/${personId}/dataset/AvailableReserveToken`, config);
};

export const getQueryMarginDataset = async (params: ISiapeMarginQueryParams, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }, 
        params
    };
    return await creditAxiosApi.get(`/Siape/FreeMarginQuery`, config);
}

export const postQueryMarginDataset = async (params: ISiapeMarginQueryParams, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }, 
        params
    };
    return await creditAxiosApi.post(`/Siape/FreeMarginQuery`, {}, config);
}