import { genericMessageRequired, handleNumericNaN } from 'helpers';
import { string, object, number, boolean, InferType, date } from 'yup';

export function validationSchemaCreditProductForm() {
    return object().shape({
        creditProductModalityId: string()
            .typeError('Modalidade inválida.')
            .required('Selecione uma modalidade.'),
        creditProductSubModalityId: string()
            .typeError('Submodalidade inválida.')
            .required('Selecione uma submodalidade.'),
        name: string()
            .typeError('Nome do Produto inválido.')
            .required('Nome do Produto: Precisa ser preenchido.'),
        categoryName: string()
            .typeError('Nome simplificado inválido.')
            .required('Nome simplificado: Precisa ser preenchido.'),
        code: number()
            .typeError('Selecione o tipo de carteira.')
            .required('Selecione o tipo de carteira.'),
        viewType: string()
            .typeError('Selecione a visibilidade do produto.')
            .required('Selecione a visibilidade do produto.'),
        templateDoc: object().when('draftGeneration', (draftGeneration) => {
            return draftGeneration === true
                ? object().shape({
                      fileName: string()
                          .typeError('Nome do modelo de contrato é obrigatório.')
                          .required('Nome do modelo de contrato: Precisa ser preenchido.'),
                  })
                : object()
                      .nullable()
                      .transform((value, _) => {
                          return null;
                      });
        }),
        amortizationType: string()
            .typeError('Selecione o tipo de cálculo.')
            .required('Selecione o tipo de cálculo.'),
        instrumentType: string()
            .typeError('Selecione o tipo de instrumento.')
            .required('Selecione o tipo de instrumento.'),
        defaultFundId: string().when('allowOwnFund', {
            is: false,
            then: string()
                .typeError('Fundo Padrão inválido.')
                .required('Fundo Padrão: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),
        minimumInterestRate: number().nullable().transform(handleNumericNaN),
        maximumInterestRate: number().nullable().transform(handleNumericNaN),
        requireGuarantor: boolean(),
        allowOwnFund: boolean(),
        maximumValueWithoutDigitalSignature: number()
            .test('length', 'Valor precisa ser no máximo R$ 2.000.000,00', (value) => {
                return value! <= 200000000;
            })
            .nullable(),
        instrumentApproval: boolean(),
        allowFinanceTaxExemption: boolean(),
        draftGeneration: boolean(),
        useSignaturePortal: boolean(),
        signatureType: number().when('signaturePortalProvider', {
            is: 0 || 1,
            then: number()
                .typeError('Forma de envio de Assinatura inválida.')
                .required('Forma de envio de Assinatura: Precisa ser preenchido.'),
            otherwise: number().nullable(),
        }),
        signaturePortalProvider: number().when('useSignaturePortal', {
            is: true,
            then: number()
                .typeError('Selecione a Certificadora.')
                .required('Selecione a Certificadora.'),
            otherwise: number().nullable(),
        }),

        unicoMinimumScoreForSignatureValidation: number().when('signaturePortalProvider', {
            is: 3,
            then: number()
                .typeError('Score mínimo Precisa ser preenchido.')
                .required('Score mínimo: Precisa ser preenchido.'),
            otherwise: number().nullable(),
        }),
        unicoTemplateName: string().when('signaturePortalProvider', {
            is: 3,
            then: string()
                .typeError('Definir template Precisa ser preenchido.')
                .required('Definir template: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),

        notifySigner: boolean().when('useSignaturePortal', {
            is: true,
            then: boolean().nullable(),
            otherwise: boolean().nullable(),
        }),
        signatureValidation: string().when('signaturePortalProvider', {
            is: 0 || 1,
            then: string()
                .typeError('Selecione o tipo de assinatura.')
                .required('Selecione o tipo de assinatura.'),
            otherwise: string().nullable(),
        }),
        automaticCreditStatusApproval: boolean(),
        automaticComplianceApproval: boolean(),
        automaticCreditAnalysisExecution: boolean(),
        automaticComplianceAnalysisExecution: boolean(),
        defaultCreditAnalysisGroup: string().when('automaticCreditAnalysisExecution', {
            is: true,
            then: string()
                .typeError('Grupo de Análise de Crédito inválido.')
                .required('Grupo de Análise de Crédito: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),
        defaultComplianceAnalysisGroup: string().when('automaticComplianceAnalysisExecution', {
            is: true,
            then: string()
                .typeError('Grupo de Análise de Compliance inválido.')
                .required('Grupo de Análise de Compliance: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),
        financeEmissioncost: boolean(),
        financeFinTax: boolean(),
        requireWitness: boolean(),
        useCollateral: boolean(),
        manualWarranty: boolean().nullable(),
        openBankAccount: boolean(),
        isConsumerCreditNote: boolean(),
        maximumPrincipalAmount: number()
            .typeError('Valor precisa ser um número.')
            .nullable()
            .transform(handleNumericNaN),
        cvmRegistrationNumber: number()
            .typeError('Número de Registro na CVM precisa ser um número.')
            .max(99999999999999999999, 'Número de Registro na CVM permite apenas 20 digitos')
            .nullable(),
        cvmRegistrationNumberIndex: number()
            .typeError('Sequencial de Registro na CVM precisa ser um número.')
            .max(99999, 'Sequencial de Registro na CVM permite apenas 5 digitos')
            .nullable(),
        cvmRegistrationDate: date()
            .typeError('Data de registro na CVM precisa ser um número.')
            .nullable(),
        maximumAbsInterestPeriods: number()
            .typeError('Valor precisa ser um número')
            .nullable()
            .transform(handleNumericNaN),
        maximumAbsAmortizationPeriods: number()
            .typeError('Valor precisa ser um número')
            .nullable()
            .transform(handleNumericNaN),
        minimumAmortizationPeriods: number()
            .typeError('Valor precisa ser um número')
            .nullable()
            .transform(handleNumericNaN),
        minimumPrincipalAmount: number()
            .typeError('Valor precisa ser um número.')
            .nullable()
            .transform(handleNumericNaN),
        maximumAutomaticLiquidationValueInCents: number()
            .typeError('Valor precisa ser um número')
            .nullable()
            .transform(handleNumericNaN),
        numberDaysValidityCustomerRegistration: number()
            .typeError('Valor precisa ser um número.')
            .nullable()
            .transform(handleNumericNaN),
        maximumEmissionCostInCents: number()
            .typeError('Valor precisa ser um número.')
            .transform(handleNumericNaN),
        minimumEmissionCostInCents: number()
            .typeError('Valor precisa ser um número.')
            .transform(handleNumericNaN),
        cosifBankAccount: string().nullable(),
        signatureValidationStep: boolean(),
        limitOpToPerson: boolean().transform((value, _) => {
            return value === null ? false : value;
        }),
        defaultCollateralDescription: string().when('useCollateral', {
            is: true,
            then: string().nullable(),
            otherwise: string().nullable(),
        }),
        timeLimitToSubmitApproval: string().typeError('Horário inválido').nullable(),
        timeLimitToApprove: string().typeError('Horário inválido').nullable(),
        timeLimitToSign: string().typeError('Horário inválido').nullable(),
        complianceApprovalTenantName: string().nullable(),
        liquidationApprovalTenantName: string().nullable(),
        signatureApprovalTenantName: string().nullable(),
        paymentFixedCosts: number().nullable(),
        insuranceCoverAmount: number().nullable(),
        warrantyRegistrationOffice: string().nullable(),
        timeToSendToSignatures: string()
            .typeError('Horário inválido')
            .required('Horário para envio de assinaturas: Precisa ser preenchido.'),
        termStartDate: string().nullable(),
        termEndDate: string().nullable(),
        creditNotePostingDeadline: string().nullable(),
        useReleaseToPaymentTrigger: boolean(),
        releaseToPaymentTriggerLimitInDays: number().when('useReleaseToPaymentTrigger', {
            is: true,
            then: number()
                .typeError('Valor precisa ser um número.')
                .required(`Período Limite: ${genericMessageRequired}`)
                .positive('Valor precisa ser um número positivo.')
                .transform(handleNumericNaN),
            otherwise: number().nullable(),
        }),
        minimumEmissionCostInPercentage: number()
            .typeError("Porcentagem mínima principal inválido")
            .nullable()
            .transform(handleNumericNaN),
        maximumEmissionCostInPercentage: number()
            .typeError("Porcentagem máxima principal inválido")
            .nullable()
            .transform(handleNumericNaN),
    });
}

let inferedSchema = validationSchemaCreditProductForm();
export type CreditProductFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesCreditProductForm = {
    creditProductModalityId: null,
    creditProductSubModalityId: null,
    name: null,
    categoryName: null,
    code: 1,
    numberDaysValidityCustomerRegistration: 0,
    viewType: 'BottomToTop',
    templateDoc: null,
    amortizationType: null,
    instrumentType: null,
    defaultFundId: null,
    minimumInterestRate: 0,
    maximumInterestRate: 0,
    requireGuarantor: false,
    bankComission: {
        amount: 0.0,
        type: 0,
    },
    financeEmissioncost: true,
    financeFinTax: true,
    allowOwnFund: false,
    maximumValueWithoutDigitalSignature: 0,
    instrumentApproval: false,
    allowFinanceTaxExemption: false,
    draftGeneration: false,
    useSignaturePortal: false,
    signatureType: 0,
    signaturePortalProvider: 0,
    automaticCreditStatusApproval: false,
    automaticComplianceApproval: false,
    automaticComplianceAnalysisExecution: false,
    automaticCreditAnalysisExecution: false,
    requireWitness: false,
    useCollateral: true,
    manualWarranty: false,
    openBankAccount: false,
    isConsumerCreditNote: false,
    maximumAbsInterestPeriods: 0,
    maximumAbsAmortizationPeriods: 0,
    minimumAmortizationPeriods: 0,
    maximumPrincipalAmount: 0,
    cvmRegistrationNumber: 0,
    cvmRegistrationNumberIndex: 0,
    cvmRegistrationDate: null,
    minimumPrincipalAmount: 0,
    maximumAutomaticLiquidationValueInCents: 0,
    maximumValueWithoutSignatureValidation: 0,
    maximumEmissionCostInCents: 0,
    minimumEmissionCostInCents: 0,
    cosifBankAccount: '',
    signatureValidationStep: false,
    signatureValidation: 0,
    limitOpToPerson: false,
    defaultCollateralDescription: '',
    timeLimitToSubmitApproval: null,
    timeLimitToApprove: null,
    timeLimitToSign: null,
    timeToSendToSignatures: null,
    notifySigner: false,
    unicoMinimumScoreForSignatureValidation: null,
    unicoTemplateName: null,
    complianceApprovalTenantName: null,
    liquidationApprovalTenantName: null,
    signatureApprovalTenantName: null,
    warrantyRegistrationOffice: 'None',
    paymentFixedCosts: 0,
    insuranceCoverAmount: 0,
    termStartDate: null,
    termEndDate: null,
    creditNotePostingDeadline: null,
    useReleaseToPaymentTrigger: false,
    releaseToPaymentTriggerLimitInDays: 10,
    minimumEmissionCostInPercentage: 0,
    maximumEmissionCostInPercentage: 0,
};
