import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useIdentity } from './identityContext';
import {
    DefaultRecordDetails,
    GetListApiResponse,
    GetListApiResponseSuccess,
    IRefetchOnMountModeGetQuery,
    useApiRequest,
} from './apiRequestContext';
import {
    getPersonsByRegistrationNumberAsync,
    getPersonsList,
    SelectedPersonType,
    PersonFiltersProps,
} from 'services/person';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { useTenant } from './tenantContext';
import { useUserPermissionData } from './userContext';
import { useFilterValues } from './filterValuesContext';
import { isEmpty } from 'lodash';
import { getNaturalPersonById, NaturalPersonReadModel } from 'services/accounts/naturalPerson';
import { getLegalPersonById, LegalPersonReadModel } from 'services/accounts/legalPerson';
import { formatDocumentNumber } from 'helpers';

export type PersonReponseParams = {
    discriminator: 'LegalPerson' | 'NaturalPerson';
    personId: string;
};

export function usePersonsList(
    filters: PersonFiltersProps,
    refetchOnMount?: IRefetchOnMountModeGetQuery
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType) ? 'Person' : filterValues.recordType;
    const listDataFilters = recordType === 'Person' && { ...filterValues.filters };
    const [personList, setPersonList] = useState<SelectedPersonType[]>([]);
    const filtersComplete = { ...filters, tenant: currentTenantId, ...listDataFilters };
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('Person', 'Read');

    const queryContextPerson = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ['persons-list', filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<PersonFull>> => {
            startRequest();
            const resp = await getPersonsList(filtersComplete, token);

            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const dataSuccess = data as GetListApiResponseSuccess<PersonFull>;
            const options = dataSuccess.data.map((item) => ({
                label: item.name,
                value: item.id,
                discriminator: item.discriminator,
                registrationNumber: item.registrationNumber,
                name: item?.name,
                optionExtraKeyValues: [
                    {
                        key: 'CPF/CNPJ',
                        value: formatDocumentNumber(item.registrationNumber),
                    },
                    {
                        key: 'Parceiro',
                        value: item.tenantDisplay,
                    },
                    {
                        key: item?.discriminator === 'NaturalPerson' ? 'Nome social' : 'Razão social',
                        value: item.alternativeName,
                    },
                ],
            }));
            setPersonList(options);

            return dataSuccess;
        },
    });

    const personAutoCompleteProps = {
        listOptions: personList,
        loading: !hasReadPermission
            ? false
            : queryContextPerson.isLoading || queryContextPerson.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ ...filterValues.filters, searchString }, 'Person'),
    };

    return {
        ...queryContextPerson,
        personAutoCompleteProps,
    };
}

export function useFetchPersonByRegistrationNumber(registrationNumber?: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const queryContextPerson = useQuery({
        enabled: !!token && !isEmpty(registrationNumber),
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-person-by-registration-number', registrationNumber],
        queryFn: async () => {
            startRequest();
            const resp = await getPersonsByRegistrationNumberAsync(registrationNumber!, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const dataSuccess = data as IQueryBasicData;
            return dataSuccess;
        },
    });

    return queryContextPerson;
}

export function useGetNaturalOrLegalPerson({ discriminator, personId }: PersonReponseParams) {
    const { token } = useIdentity();
    const isLegalPerson = discriminator === 'LegalPerson';
    const queryKey = ['get-person', personId];
    const query = isLegalPerson
        ? {
            queryKey,
            enabled: !!personId && !!discriminator,
            queryFn: async () => {
                const { data } = await getLegalPersonById(personId, token!);
                return data as LegalPersonReadModel;
            },
        }
        : {
            queryKey,
            enabled: !!personId && !!discriminator,
            queryFn: async () => {
                const { data } = await getNaturalPersonById(personId, token!);
                return data as NaturalPersonReadModel;
            },
        };
    return useQuery(query as UseQueryOptions<LegalPersonReadModel | NaturalPersonReadModel>);
}

export interface PersonFull extends DefaultRecordDetails {
    registrationNumber: string;
    name: string;
    discriminator: string;
    riskRating: string;
    datasets: string[];
    alternativeName: string;
}

export interface IQueryBasicData {
    naturalPerson: NaturalPersonReadModel;
    legalPerson: LegalPersonReadModel;
    phones: string[];
    addresses: AddressesQueryBasicData[];
    emails: string[];
    professionData: any;
}

export interface AddressesQueryBasicData {
    addressName: string;
    zipCode: string;
    city: string;
    uf: string;
    ufDisplay: string;
    district: string;
    number: string;
    complement: string;
}
