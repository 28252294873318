import { Drawer } from '@uy3/web-components';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    defaultvaluesMarginQueryForm,
    validationSchemaMarginQueryForm,
    MarginQueryForm,
    TMarginQueryProps,
} from '../MarginQueryForm';
import { isValidCPF, onlyNumbers } from 'helpers';
import { useState } from 'react';
import { useCheckPurchaseContracts } from 'contexts/zetra';
import UpdateConsignmentForPurchase from './update/UpdateConsignment';
import { ToastType } from 'contexts/apiRequestContext';
import { SelectConsignmentContainer } from './SelectConsignment';
import { CreditProductReadModel } from 'services/creditProduct';

type ConsignmentContainerProps = {
    openDrawer: boolean;
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    productData: CreditProductReadModel | undefined
};

export default function ConsignmentContainer({
    openDrawer,
    onClose,
    setToast,
    productData
}: ConsignmentContainerProps) {
    const { getValues } = useFormContext();
    const [updateConsignment, setUpdateConsignment] = useState(false);
    const [openSelectConsignment, setOpenSelectConsignment] = useState(false);
    const [getRegistrationNumber, setGetRegistrationNumber] = useState<string | undefined>(
        getValues('registrationNumber') ?? ''
    );

    const registrationNumber = onlyNumbers(getRegistrationNumber!);
    const { data: purchaseContractsData, refetch } = useCheckPurchaseContracts({ registrationNumber });

    const handleSubmit = ({ registrationNumber }: TMarginQueryProps) => {
        setGetRegistrationNumber(registrationNumber);
        setOpenSelectConsignment(true);
        refetch();
    };

    const personId = getValues('personId')

    const renderDrawerContent = () => {
        if (openSelectConsignment) {
            return (
                <SelectConsignmentContainer
                    {...{
                        setOpenSelectConsignment,
                        setUpdateConsignment,
                        checkPurchaseContracts: purchaseContractsData,
                        setToast,
                        onClose,
                        registrationNumber, 
                        productData
                    }}
                />
            );
        }

        return (
            <FormProvider
                validationSchema={validationSchemaMarginQueryForm()}
                defaultValues={defaultvaluesMarginQueryForm}
                onSubmit={handleSubmit}
                onChangeField={[
                    {
                        fieldName: 'registrationNumber',
                        delegate: (value: string) => {
                            if (isValidCPF(value)) {
                                setGetRegistrationNumber(value);
                            }
                        },
                    },
                ]}
            >
                <MarginQueryForm
                    {...{
                        onClose,
                        isLoading: false,
                        registrationNumber: getValues('registrationNumber') ?? registrationNumber,
                        setGetRegistrationNumber,
                        isZetra: true,
                        personId
                    }}
                />
            </FormProvider>
        );
    };

    return (
        <>
            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={onClose}
                title={!purchaseContractsData ? 'Selecione o consignado' : 'Consultar contratos passíveis de compra'}
                description={
                    !purchaseContractsData
                        ? 'Escolha uma opção para continuar'
                        : 'Preencha o campo abaixo para realizar o consignado'
                }
            >
                {renderDrawerContent()}
            </Drawer>

            <UpdateConsignmentForPurchase
                {...{
                    openDrawer: updateConsignment,
                    onCloseDrawer: () => setUpdateConsignment(false),
                    registrationNumber,
                    setToast,
                }}
            />
        </>
    );
}
