import { Stack } from '@mui/material';
import { BankIcon, Button, Typography } from '@uy3/web-components';
import CardInfo from 'components/Custom/CustomCardInfo/CardInfo';
import { CustomBox } from 'components/Custom/CustomMUI';
import { useFormContext } from 'contexts/formContext';
import { BankDetails } from 'pages/BankAccount/BankDetailsTab/BankDetails';
import React from 'react';
import { activeTheme } from 'services/theme';

interface BankInformationFormProps {
    onClose: () => void;
    personDisplay: string;
    isLegalPerson?: boolean;
    handleOpenBankAccount?: () => void
    showActionToOpenBankAccount?: boolean
}

const theme = activeTheme();

export default function BankInformationForm({
    onClose,
    personDisplay,
    isLegalPerson = false,
    handleOpenBankAccount,
    showActionToOpenBankAccount
}: BankInformationFormProps) {
    const { watch } = useFormContext();
    const name = watch('name') ?? personDisplay;
    const registrationNumber = watch('registrationNumber');

    return (
        <React.Fragment>
            <CardInfo {...{ name, registrationNumber }} />
            {showActionToOpenBankAccount &&
                <Stack direction='row' alignItems='center'  justifyContent='right'>
                    <Button
                        name="btn-add-account"
                        startIcon={
                            <BankIcon
                                htmlColor={theme.palette.primary.main}
                                width={22}
                                height={22}
                            />
                        }
                        variant="text"
                        onClick={handleOpenBankAccount}
                    >
                        Reservar número de conta
                    </Button>
                </Stack>}
            <Stack spacing={3} direction="column" my={3}>
                <CustomBox container>
                    <Typography
                        variant="md"
                        fontWeight={600}
                        lineHeight="21.6px"
                        letterSpacing="-0.2px"
                    >
                        Sobre os dados bancários
                    </Typography>
                    <Typography variant="xs">
                        O envio dos dados bancários é obrigatório para seguir com sua solicitação.
                    </Typography>
                </CustomBox>
                <Typography variant="lg" color="common.black">
                    Informações bancárias
                </Typography>
            </Stack>
            <BankDetails isLegalPerson={isLegalPerson} onClose={onClose} />
        </React.Fragment>
    );
}
