import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { handleNumericNaN } from 'helpers';
import { CommissionRangeType } from 'services/creditProduct';
import { InferType, array, number, object, string, boolean } from 'yup';

const genericMessage = 'Precisa ser preenchido.';

const baseValueOptions = ['InitialValue', 'RequestedValue'];

const DetailsSchema = object().shape({
    name: string()
        .typeError('Descrição precisa ser um texto.')
        .required(`Descrição do custo: ${genericMessage}`)
        .nullable(),
    amount: number()
        .typeError('Valor precisa ser um número.')
        .required(`Valor do custo: ${genericMessage}`)
        .moreThan(-1, 'Informe um valor válido maior ou igual a 0.')
        .transform(handleNumericNaN)
        .nullable(),
    type: string()
        .oneOf(['Absolute', 'Percentage'], 'Opção inválida.')
        .typeError('O Tipo do custo de emissão é inválido.')
        .required(`Tipo do custo de emissão: ${genericMessage}`)
        .nullable(),
    baseValue: string().when('type', {
        is: (value: string) => value === 'Percentage',
        then: string()
            .oneOf(baseValueOptions, 'Opção inválida.')
            .typeError('O Tipo base da comissão é inválido.')
            .required(` Tipo base da comissão : ${genericMessage}`),
        otherwise: string().nullable().notRequired(),
    }),
    chargeCommissionFrom: string()
        .typeError("Cobrar comissão de: precisa ser preenchido")
        .required("Cobrar comissão de: precisa ser preenchido"),
    isCustomerRegistrationFee: boolean(),
});

export const commissionRangeValidationSchema = (
    minimumPrincipalAmount: number,
    maximumPrincipalAmount: number,
    commissionRange: CommissionRangeType[],
    currentIndex: number | undefined
) => {
    const numberValidation = (isMinimum: boolean) => {
        const formattedMinAmount = formatCurrencyInCents(minimumPrincipalAmount);
        const formattedMaxAmount = formatCurrencyInCents(maximumPrincipalAmount);

        return number()
            .required(`Valor ${isMinimum ? 'mínimo' : 'máximo'} principal: ${genericMessage}`)
            .positive()
            .typeError(`Valor ${isMinimum ? 'mínimo' : 'máximo'} principal precisa ser um número.`)
            .transform(handleNumericNaN)
            .min(
                minimumPrincipalAmount,
                `Valor ${isMinimum ? 'mínimo' : 'máximo'
                } do principal precisa ser maior que ${formattedMinAmount}`
            )
            .max(
                maximumPrincipalAmount,
                `Valor ${isMinimum ? 'mínimo' : 'máximo'
                } do principal precisa ser menor que ${formattedMaxAmount}`
            )
            .test(
                `${isMinimum ? 'min' : 'max'}-gap`,
                `O valor ${isMinimum ? 'mínimo' : 'máximo'} não pode ser igual ou ${isMinimum ? 'maior' : 'menor'
                } que o "Valor ${isMinimum ? 'máximo' : 'mínimo'}  do principal".`,
                (val, { parent }) => {
                    if (!val) return true;

                    const { minimumPrincipalAmount, maximumPrincipalAmount } = parent;

                    var minGreaterThanMax = val > maximumPrincipalAmount; // valor mínimo não pode ser maior que o máximo
                    var maxNotLessThanMin = val <= minimumPrincipalAmount; // valor máximo não pode ser menor que o mínimo

                    if (isMinimum && minGreaterThanMax) {
                        return false;
                    }

                    if (!isMinimum && maxNotLessThanMin) {
                        return false;
                    }

                    return true;
                }
            );
    };

    const validateGaps = (isMinimum: boolean) => {
        const numberValidator = numberValidation(isMinimum);

        const errorMessage = isMinimum
            ? "O valor mínimo especificado deve ser maior que o valor máximo principal das faixas anteriores."
            : "O valor máximo especificado deve ser maior que o valor máximo principal das faixas anteriores e menor que o valor mínimo principal da faixa posterior."

        if (commissionRange.length > 0) {
            return numberValidator.test(`${isMinimum ? 'min' : 'max'}-gap`, errorMessage, (val, { parent }) => {
                if (!val) return true;

                const { minimumPrincipalAmount, maximumPrincipalAmount } = parent;

                const isMinimumAmountValid = validateAmount(minimumPrincipalAmount, currentIndex);;
                const isMaximumAmountValid = validateAmount(maximumPrincipalAmount, currentIndex);

                let nextCommission = true;
                if (isMinimum && isMinimumAmountValid) {
                    return (nextCommission = false);
                }

                if (!isMinimum && isMaximumAmountValid) {
                    return (nextCommission = false);
                }

                return nextCommission;
            }
            );
        }

        function validateAmount(amount: number, currentIndex: number | undefined) {

            const previousComm = getCommissionAtIndex(currentIndex! - 1);
            const commissionSubsequent = getCommissionAtIndex(currentIndex! + 1);
            const isMaximumAmountValid = previousComm && amount <= previousComm?.maximumPrincipalAmount;
            const isMinimumAmountValid = commissionSubsequent && amount >= commissionSubsequent?.minimumPrincipalAmount;
            return isMaximumAmountValid || isMinimumAmountValid;
        }

        function getCommissionAtIndex(index: number) {
            return index >= 0 && index < commissionRange.length ? commissionRange[index] : undefined;
        }

        return numberValidator;
    };

    return object().shape({
        minimumPrincipalAmount: validateGaps(true),
        maximumPrincipalAmount: validateGaps(false),
        details: array().of(DetailsSchema).nullable(),
    });
};

export type CommissionDetailsProps = InferType<typeof DetailsSchema>;

export const defaultValuesCommissionRange = (
    minimumPrincipalAmount: number,
    maximumPrincipalAmount: number,
    comissionRange: CommissionRangeType[]
) => {
    if (comissionRange.length === 0) {
        return {
            minimumPrincipalAmount: minimumPrincipalAmount,
            maximumPrincipalAmount: maximumPrincipalAmount,
            details: [
                {
                    baseValue: 'InitialValue',
                    chargeCommissionFrom: 'All'
                },
            ],
        };
    } else {
        var lastComission = comissionRange[comissionRange.length - 1];
        return {
            minimumPrincipalAmount: lastComission.maximumPrincipalAmount + 1,
            maximumPrincipalAmount: maximumPrincipalAmount,
            details: [
                {
                    baseValue: 'InitialValue',
                    chargeCommissionFrom: 'All',
                    isCustomerRegistrationFee: true
                },
            ],
        };
    }
};
