/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, IconButton, Stack } from '@mui/material';
import { GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { BankSlipIcon, DataTable, EditIcon, SaveIcon, SearchIcon } from '@uy3/web-components';
import { RowActions } from 'components/RowActions/RowActions';
import { isEmpty } from 'lodash';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { BillingFull } from 'services/walletManagement/billing/billing.types';
import { useSelectColumns } from 'contexts/SelectColumnContexts';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { BillingFiltersContainer } from 'components/DataTableFilters/BillingFilters/BillingFiltersContainer';
import { RenderColumnStatusBilling } from 'components/RenderColumnStatusBilling/RenderColumnStatusBilling';
import moment from 'moment';

export const defaultFieldsNamesColumn = [
    'documentNumber',
    'name',
    'walletCode',
    'amount',
    'createdAt',
    'dueDate',
    'statusDisplay'
];


type ChargesListProps = {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    rowsSelected?: string[] | GridRowId[]
    handleSelectionModelChange?: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
    ) => void;
    setActionStep?: React.Dispatch<React.SetStateAction<{
        step: string;
        data?: BillingFull;
    } | undefined>>
    checkboxSelection?: boolean
    filtersOptions?: string[]
}

export function ChargesList({
    page,
    queryData,
    rowsPerPage,
    setActionStep,
    setPage,
    setRowsPerPage,
    rowsSelected,
    handleSelectionModelChange,
    checkboxSelection = true,
    filtersOptions
}: ChargesListProps) {
    const navigate = useNavigate();
    const { selectedColumns, setColumns, setDefaultValues, defaultValues } = useSelectColumns();

    useEffect(() => {
        setColumns(columns);
        if (defaultFieldsNamesColumn.length) {
            setDefaultValues(defaultFieldsNamesColumn);
        }
    }, []);

    const columnsLisaDeCobrancas: GridColDef[] = [
        {
            field: 'documentNumber',
            headerName: 'Nº do documento',
            hideSortIcons: true,
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            editable: false,
            renderCell: (cellValues) => {
                return cellValues.value ?? "N/D"
            },
        },
        {
            field: 'name',
            headerName: 'Devedor',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div>
                        {cellValues.row?.payer?.name ?? "N/D"}
                    </div>
                );
            },
        },
        {
            field: 'email',
            headerName: 'Email do devedor',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div>
                        {cellValues.row?.payer?.email ?? "N/D"}
                    </div>
                );
            },
        },
        {
            field: 'registrationNumber',
            headerName: 'CPF/CNPJ do devedor',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            renderCell: (cellValues) => {
                let registrationNumber = String(cellValues.row?.payer?.registrationNumber);
                return (
                    <div>
                        {formatDocumentNumber(registrationNumber) ?? "N/D"}
                    </div>
                );
            },
        },
        {
            field: 'personTypeValueDisplay',
            headerName: 'Tipo de pessoa',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div>
                        {cellValues.row?.payer?.personTypeValueDisplay ?? "N/D"}
                    </div>
                );
            },
        },
        {
            field: 'statusRegisterValueDisplay',
            headerName: 'Sitaução de registro',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div>
                        {cellValues.row?.bankSlip?.statusRegisterValueDisplay ?? "N/D"}
                    </div>
                );
            },
        },
        {
            field: 'description',
            headerName: 'Descrição',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            renderCell: (cellValues) => cellValues.row?.description ?? "N/D"
        },
        {
            field: 'guarantorName',
            headerName: 'Nome sacador avalista',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            renderCell: (cellValues) => cellValues.row?.guarantor?.name ?? "N/D"
        },
        {
            field: 'guarantorRegistrationNumber',
            headerName: 'CPF/CNPJ sacador avalista',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            renderCell: (cellValues) => {
                const registrationNumber = cellValues?.row?.guarantor?.registrationNumber;
                return registrationNumber !== null ? formatDocumentNumber(String(registrationNumber)) : "N/D";
            }
        },
        {
            field: 'walletCode',
            headerName: 'Código convênio',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div >
                        {cellValues.value ?? "N/D"}
                    </div>
                );
            },
        },
        {
            field: 'amount',
            headerName: 'Valor da cobrança',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => {
                return formatCurrencyInCents(value)
            }
        },
        {
            field: 'createdAt',
            headerName: 'Criado em',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => {
                return !isEmpty(value) ? new Date(value).toLocaleDateString('pt-BR') : "N/D"
            }
        },
        {
            field: 'dueDate',
            headerName: 'Data de vencimento',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => {
                return !isEmpty(value) ? moment(value).format('L') : "N/D"
            }
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            flex: 3,
            editable: false,
            align: "center",
            headerAlign: 'center',
            renderCell: ({ value, id, row: { statusValue } }) => {
                return <RenderColumnStatusBilling {...{
                    onClick: () => navigate(`/gestao-carteira/cobrancas/${id}`),
                    statusDisplay: value!,
                    statusValue: statusValue!, 
                    resource: 'BillingList'
                }} />
            }
        },
        {
            field: "actions",
            headerName: 'Ações',
            hideSortIcons: true,
            flex: 0,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            renderCell: ({ row }) => {

                const isRegistred = row?.registered === true;
                const redirectToForm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    event.stopPropagation();
                    navigate(`/gestao-carteira/cobrancas/${row?.id}`);
                }
                return (
                    <Stack direction='row' alignItems='center'>
                        <IconButton onClick={(e) => redirectToForm(e)}>
                            <BankSlipIcon />
                        </IconButton>
                        <RowActions
                            listButtons={[
                                {
                                    action: () => setActionStep && setActionStep({ data: row, step: "registerBankSlip" }),
                                    disabled: false,
                                    hide: isRegistred,
                                    icon: <SaveIcon />,
                                    label: "Registrar boleto"
                                },
                                {
                                    action: () => setActionStep && setActionStep({ data: row, step: "editBilling" }),
                                    disabled: false,
                                    hide: row?.statusValue !== 'NotRegistered' && isRegistred,
                                    icon: <EditIcon />,
                                    label: "Edição rápida"
                                },
                                {
                                    action: () => setActionStep && setActionStep({ data: row, step: "viewDetails" }),
                                    disabled: false,
                                    icon: <SearchIcon />,
                                    label: "Ver detalhes"
                                }
                            ]}
                        />
                    </Stack>
                )
            }
        }
    ]

    const columns = columnsLisaDeCobrancas as GridColDef[];
    const columnsActions = columns?.filter((x) => x.field === 'actions');

    const filteredColumns = columns?.filter((column) =>
        selectedColumns?.length === 0
            ? defaultValues?.includes(column?.field)
            : selectedColumns?.includes(column?.field)
    );

    const columnsToList = [...filteredColumns, ...columnsActions];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: (
                    <Grid mb={-0.5}>
                        <BillingFiltersContainer filtersOptions={filtersOptions}/>
                    </Grid>
                ),
            }}
            sx={{
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
            }}
            columns={columnsToList ?? []}
            rows={queryData?.data as BillingFull[] ?? []}
            getRowId={(item) => item.id}
            rowsPerPage={rowsPerPage}
            pageCount={queryData?.totalPages ?? 0}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            onRowClick={(row) => navigate(`/gestao-carteira/cobrancas/${row?.id}`)}
            checkboxSelection={checkboxSelection}
            keepNonExistentRowsSelected={true}
            rowSelectionModel={rowsSelected}
            onSelectionModelChange={handleSelectionModelChange}
        />
    );
}
