import { Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { SelectFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useFormContext } from 'contexts/formContext';
import { OperatorsListType } from 'contexts/OperatorContext';
import { useTenant } from 'contexts/tenantContext';
import { handlePersonLink } from 'helpers';
import { IRelatedPersonCreateOrUpdateModel } from 'services/bankAccountProduct/types/generics';
import { SelectedPersonType } from 'services/person';
import { activeTheme } from 'services/theme';

type InvolvedBankAccountProductFormProps = {
    personList: AutocompleteOptionList;
    onClose: () => void;
    listOperator: AutocompleteOptionList;
    currentInvolved?: IRelatedPersonCreateOrUpdateModel
};

const theme = activeTheme();
export const InvolvedBankAccountProductForm = ({
    personList,
    onClose,
    listOperator,
    currentInvolved
}: InvolvedBankAccountProductFormProps) => {
    const { tenantAutoCompleteProps } = useTenant();
    const { watch, setValue } = useFormContext();
    const [typeOfRelationship, personDiscriminator, relatedToId, relatedToDiscriminator] = watch([
        'typeOfRelationship',
        'personDiscriminator',
        'relatedToId',
        'relatedToDiscriminator',
    ]);

    const billingAgentApproverUser = 'BillingAgentApproverUser';
    const isBillingAgent = typeOfRelationship === billingAgentApproverUser;
    const isNaturalPerson = personDiscriminator === 'NaturalPerson' || currentInvolved?.discriminator === 'NaturalPerson'

    return (
        <Stack spacing={2}>
            <AutocompleteField
                label="Pessoa"
                name="personId"
                displayName="personIdDisplay"
                required
                {...personList}
                onChange={(_, options) => {
                    const selected = options as SelectedPersonType;

                    setValue('personId', selected?.value);
                    setValue('personDisplay', selected?.label);
                    if (selected?.discriminator)
                        setValue('personDiscriminator', selected.discriminator);
                    if (selected?.registrationNumber)
                        setValue('personRegistrationNumber', selected.registrationNumber);
                }}
            />

            <SelectFormField
                label="Tipo de relação"
                name="typeOfRelationship"
                required
                options={[
                    { label: 'Procurador', value: 'Proxy' },
                    { label: 'Sócio', value: 'Partner' },
                    { label: 'Cônjuge', value: 'Spouse' },
                    { label: 'Testemunha', value: 'Witness' },
                    { label: 'Credor', value: 'Creditor' },
                    { label: 'Agente de cobrança', value: 'BillingAgent' },
                    { label: 'Usuário aprovador', value: 'BillingAgentApproverUser' },
                ]}
            />

            {isBillingAgent && (
                <>
                    {isNaturalPerson && (
                        <AutocompleteField
                            name="relatedToId"
                            label="Relacionada a"
                            displayName="relatedToIdDisplay"
                            link={handlePersonLink(relatedToId, relatedToDiscriminator)}
                            showEndIcon={relatedToId}
                            endIconType="link"
                            preserveLastResult
                            {...personList!}
                        />
                    )}
                    <AutocompleteField
                        name="tenantName"
                        displayName="tenantNameDisplay"
                        label="Correspondente"
                        required
                        {...tenantAutoCompleteProps}
                    />
                    <AutocompleteField
                        label="Operadores"
                        name="userId"
                        displayName="userIdDisplay"
                        required
                        {...listOperator}
                        onChange={(_, options) => {
                            const selected = options as OperatorsListType;

                            setValue('userId', selected?.value);
                            setValue('userIdDisplay', selected?.label);
                            if (selected?.registrationNumber)
                                setValue('userIdRegistrationNumber', selected.registrationNumber);
                        }}
                    />
                    <SelectFormField
                        name="level"
                        label="Nível de permissão"
                        required
                        options={[
                            { label: 'Visualizador', value: 'Viewer' },
                            { label: 'Aprovador em Conjunto', value: 'JointApprover' },
                            { label: 'Aprovador Master', value: 'MasterApprover' },
                            { label: 'Solicitante', value: 'Requester' },
                        ]}
                    />
                </>
            )}
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    name="btn-add-involved-form"
                    type="submit"
                    startIcon={<SaveIcon htmlColor={theme.palette.primary.contrastText} />}
                    variant="contained"
                    size="medium"
                >
                    Adicionar envolvido
                </Button>
            </Stack>
        </Stack>
    );
};
