import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { sendSubmitEvent } from 'contexts/apiRequestContext';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants';
import { firstInstructionDebtCollectionOptions } from 'services/walletManagement/billing/billing.types';

export const billingStatusOptions = [
    { label: "Em carteira", value: "NotRegistered" },
    { label: "Registrando", value: "Registering" },
    { label: "Registrado", value: "Registered" },
    { label: "Pago", value: "Paid" },
    { label: "Baixado", value: "Cancelled" },
    { label: "Em cartório", value: "InDebtCollection" },
    { label: "Instrução de Protesto", value: "ToDebtCollection" },
    { label: "Protestado", value: "ConfirmedDebtCollection" }, 
    { label: "Vencido", value: "OverDue" }, 
    { label: "Devolvido cartório", value: "ReturnedFromDebtCollection" }, 
    { label: "Sustar cartório manter carteira", value: "CancelDebtCollectionRegisterBilling" }, 
    { label: "Sustar cartório com baixa", value: "CancelDebtCollectionCancelBilling" }, 
]

type BillingFiltersFormProps = {
    filtersOptions: string[]
}

function BillingFiltersForm({ filtersOptions }: BillingFiltersFormProps) {
    const { watch } = useFormContext();
    const isLegalPerson = watch('payerRegistrationNumber')?.replace(/\D/g, '')?.length > 11;

    
    const getOptionsFilter = () => {
        var options = [
            { label: 'Todos', value: 'searchString' },
            { label: 'Status', value: 'statusesValue' },
            { label: 'Data de vencimento', value: 'initialDueDate' },
            { label: 'Data do crédito', value: 'initialSettlementDate' },
            { label: 'Data de criação', value: 'initialCreatedDate' },
            { label: 'Nome do devedor', value: 'payerName' },
            { label: 'CPF/CNPJ do devedor', value: 'payerRegistrationNumber' },
            { label: 'Código convênio', value: 'walletsCode' },
            { label: 'Código de barras', value: 'barCode' },
            { label: 'Nº do documento', value: 'documentNumber' },
            { label: 'Valor da cobrança', value: 'amount' },
            { label: 'Instrução de protesto', value: 'firstInstructionDebtCollection' },
        ];

        if (filtersOptions.length > 0 ) {
            return options.filter(x => 
                filtersOptions?.map(x=> x.toLowerCase())
                .includes(x.value.toLowerCase())
            );
        }

        return options;
    }
    

    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={getOptionsFilter()}
            />
            {watch('optionFilter') === 'searchString' && (
                <TextFormField
                    name="searchString"
                    variant="outlined"
                    label="Filtrar por todos"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'payerName' && (
                <TextFormField
                    name="payerName"
                    variant="outlined"
                    label="Filtrar por nome do devedor"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'firstInstructionDebtCollection' && (
                <SelectFormField
                    label="Instrução de protesto"
                    name="firstInstructionDebtCollection"
                    options={firstInstructionDebtCollectionOptions}
                    showEndAdornment
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'payerRegistrationNumber' && (
                <TextFormField
                    label="Filtrar por CPF/CNPJ"
                    name="payerRegistrationNumber"
                    fullWidth
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'barCode' && (
                <TextFormField
                    name="barCode"
                    variant="outlined"
                    label="Filtrar por código de barras"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'walletsCode' && (
                <TextFormField
                    name="walletsCode"
                    variant="outlined"
                    label="Filtrar por código convênio"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'documentNumber' && (
                <TextFormField
                    name="documentNumber"
                    variant="outlined"
                    label="Filtrar por Nº do documento"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'amount' && (
                <CurrencyFormField
                    fullWidth
                    label="Valor da cobrança"
                    name="amount"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'statusesValue' && (
                <SelectFormField
                    label="Filtrar por status"
                    name="statusesValue"
                    fullWidth
                    showEndAdornment
                    options={billingStatusOptions}
                    onClick={sendSubmitEvent}
                />
            )}

            {watch('optionFilter') === 'initialCreatedDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Data de criação inicial"
                        name="initialCreatedDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data de criação final"
                        name="finalCreatedDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
            {watch('optionFilter') === 'initialDueDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Filtrar por data inicial"
                        name="initialDueDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data final"
                        name="finalDueDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
            {watch('optionFilter') === 'initialSettlementDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Data do crédito inicial"
                        name="initialSettlementDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Data do crédito final"
                        name="finalSettlementDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
        </>
    );
}

type BillingFiltersProps = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
    filtersOptions: string[]
};

export const BillingFilters = ({ currentValues, removeFilterSelected, filtersOptions }: BillingFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<BillingFiltersForm filtersOptions={filtersOptions}/>}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                    recordType='billing-list-columns'
                    enableSelectFilter
                />
            }
        />
    );
};
