import { FormProvider, useFormContext } from 'contexts/formContext';
import SelectConsignmentForm from './SelectConsignmentForm';
import {
    defaultValuesSelectConsignment,
    TSelectConsignmentProps,
    validationSchemaSelectConsignment,
} from './SelectConsignmentSchema';
import { FieldErrors } from 'react-hook-form';
import { CheckPurchaseContractsReadModel } from 'services/zetra';
import { showInfoToast, ToastType } from 'contexts/apiRequestContext';
import { mapConsignmentWarranty } from 'services/payrollAgreement';
import { CreditProductReadModel } from 'services/creditProduct';

type SelectConsignmentProps = {
    setOpenSelectConsignment: Function;
    setUpdateConsignment: Function;
    checkPurchaseContracts: CheckPurchaseContractsReadModel | undefined;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    onClose: () => void;
    registrationNumber: string;
    productData: CreditProductReadModel | undefined
    personId?: string
};

function SelectConsignmentContainer({
    setOpenSelectConsignment,
    setUpdateConsignment,
    checkPurchaseContracts,
    setToast,
    onClose,
    registrationNumber,
    productData,
    personId
}: SelectConsignmentProps) {
    const { setValue } = useFormContext();

    const handleSubmit = ({ selectConsignment, ...props }: TSelectConsignmentProps) => {
        const abstracts = checkPurchaseContracts?.resumos;

        const selectedItems = abstracts?.filter((f) => {
            const value = `${f?.adeNumero}-${f?.adeIdentificador}`;
            return selectConsignment.includes(value);
        });

        if (!!selectedItems?.length) {
            const totalValorParcela: number = selectedItems.reduce((acc, current) => acc + current.valorParcela, 0);

            const mappedWarrantyModels = selectedItems.map((item) =>{
                const additionalInformation = { 
                    ...props, 
                    adeNumero: item.adeNumero, 
                    matricula: props?.employeeCode,
                    name: props?.payrollAgreementName,
                    id: props?.payrollAgreementId
                }; 
                return mapConsignmentWarranty([item], additionalInformation, registrationNumber)}
            );

            setValue('warranty', mappedWarrantyModels);
            if (totalValorParcela > 0) {
                setValue('amortization.requestedAmount', totalValorParcela * 100);
            }
        }

        onClose();
    };

    return (
        <FormProvider
            validationSchema={validationSchemaSelectConsignment}
            defaultValues={defaultValuesSelectConsignment}
            onSubmit={handleSubmit}
            onError={(err: FieldErrors<TSelectConsignmentProps>) => {
                const erroMessage = Object.values(err)
                    .map((error) => error?.message)
                    .join('\n');
                showInfoToast('Atenção! Verifique os campos obrigatórios.', erroMessage, setToast);
            }}
        >
            <SelectConsignmentForm
                {...{ setOpenSelectConsignment, setUpdateConsignment, checkPurchaseContracts, productData, personId }}
            />
        </FormProvider>
    );
}

export default SelectConsignmentContainer;
