import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { billingStatusOptions } from 'components/DataTableFilters/BillingFilters/BillingFilters';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { FilterProvider } from 'contexts/filterContext';
import { useFilterValues } from 'contexts/filterValuesContext';
import { useBillingListData } from 'contexts/wallet/Billing/billingContext';
import { ChargesList } from 'pages/WalletManagement/Billing/BillingList/BillingList';
import { useState } from 'react';
import { useParams } from 'react-router';

export const InsatallmentBillingsList = () => {
    const { installmentId } = useParams();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { filterValues } = useFilterValues();

    const hasFilters = Object.values(filterValues.filters).length > 0
    const { data: billingListData, isLoading: chargesIsLoading } = useBillingListData({ 
        page, 
        size: rowsPerPage, 
        installmentId,
        isOrderByDueDate: true 
    });

    const onChangePage = (page: number) => setPage(page);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    return (
        <Stack spacing={2} pr={2}>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="25px"
                lineHeight="38.4px"
            >
                Cobranças do parcelamento
                <Typography
                    variant="h6"
                    color="neutral.medium"
                    sx={{
                        lineHeight: { xs: '20px', md: '20px' },
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                        opacity: '87%',
                    }}
                >
                    Confira a lista de cobranças referente ao parcelamento
                </Typography>
            </Typography>

            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text' },
                    statusesValue: {
                        label: 'Status',
                        type: 'select',
                        multiple: true,
                        options: billingStatusOptions
                    },
                    initialDueDate: {
                        label: 'Data de vencimento',
                        type: 'dateRange',
                        multiple: false,
                        dateRangeName: 'finalDueDate',
                    },
                    initialSettlementDate: {
                        label: 'Data do crádito',
                        type: 'dateRange',
                        multiple: false,
                        dateRangeName: 'finalSettlementDate',
                    },
                    documentNumber: { label: 'Nº do documento', type: 'text' },
                    barCode: { label: 'Código de barras', type: 'text' },
                    amount: { label: 'Valor de cobrança', type: 'text' }
                }}
            >
                <ListSkeleton {...{ isLoading: chargesIsLoading && !hasFilters }}>
                    <ChargesList {...{
                        page,
                        queryData: billingListData,
                        rowsPerPage,
                        setRowsPerPage: onChangeRowsPerPage,
                        setPage: onChangePage,
                        checkboxSelection: false,
                        filtersOptions: [
                            "searchString",
                            "statusesValue",
                            "initialDueDate",
                            "initialSettlementDate",
                            "barCode",
                            "documentNumber"
                        ]
                    }} />

                </ListSkeleton>

            </FilterProvider>
        </Stack>
    )
}
