/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { cpfMask } from 'utils/constants';
import { useFormContext } from 'contexts/formContext';
import React, { useEffect } from 'react';
import { activeTheme } from 'services/theme';

type SiapeQueryFormProps = {
    onClose: () => void;
    isLoading: boolean;
    registrationNumber?: string;
    personId?: string | undefined;
    isZetra?: boolean;
    setGetRegistrationNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const color = activeTheme().palette;

export const MarginQueryForm: React.FC<SiapeQueryFormProps> = ({
    onClose,
    isLoading,
    registrationNumber,
    setGetRegistrationNumber,
    isZetra = false,
    personId,
}) => {
    const { submitting, setValue } = useFormContext();

    useEffect(() => {
        if (isZetra) {
            if (personId) {
                setValue('registrationNumber', registrationNumber);
                setGetRegistrationNumber(registrationNumber)
            } else {
                setValue('registrationNumber', '');
                setGetRegistrationNumber('');
            }
        } else if (registrationNumber) {
            setValue('registrationNumber', registrationNumber);
        }
    }, []);

    return (
        <Stack spacing={2}>
            <TextFormField
                name="registrationNumber"
                disabled={!!registrationNumber}
                label="CPF"
                variant="outlined"
                required
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { mask: cpfMask },
                }}
                fullWidth
            />

            <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon htmlColor={color.primary.main} />}
                    onClick={() => {onClose(); setGetRegistrationNumber(undefined)}}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    disabled={submitting || isLoading}
                    startIcon={<CompleteIcon htmlColor={color.common.white} />}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};
