import { useMutation, useQuery } from '@tanstack/react-query';
import {
    editOrCreateCreditProduct,
    getCreditProductById,
    getModalityListAsync,
    getProductsList,
    getSubModalityListAsync,
    Modality,
    ModalityListProps,
    ProductFiltersProps,
    CreditProductReadModel,
    SubModalityProps,
} from 'services/creditProduct';
import { useIdentity } from './identityContext';
import {
    ApiResponseError,
    GetListApiResponse,
    GetListApiResponseSuccess,
    IRefetchOnMountModeGetQuery,
    useApiRequest,
} from './apiRequestContext';
import { isAxiosError } from 'axios';
import { useTenant } from './tenantContext';
import { useState } from 'react';
import { CreditProductFormSchema } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductSchema';
import { useFilterValues } from './filterValuesContext';
import { useUserPermissionData } from './userContext';
import { isEmpty } from 'lodash';
import { getCosifAccountTransaction } from 'services/creditNote';
import { handleErrorUseQuery } from 'helpers';

export function useCreditProductData(productId?: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('CreditProduct', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && !!productId && productId !== 'novo' && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['product-credit', productId],
        queryFn: async (): Promise<CreditProductReadModel> => {
            startRequest();
            const { data, status, statusText } = await getCreditProductById(
                productId!,
                token,
                currentTenantId
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as CreditProductReadModel;
        },
    });

    return {
        creditProductStatus: status,
        creditProductData: data,
        creditProductErr: error,
        creditProductFetch: isFetching,
        refetch,
        creditProductLoading: isLoading,
    };
}

type ProductOptionList = {
    label: string;
    value: string;
    amortizationType: string;
    maximumInterestRate: number;
    minimumInterestRate: number;
    maximumPrincipalAmount: number;
    minimumPrincipalAmount: number;
    maximumAutomaticLiquidationValueInCents: number;
    allowFinanceTaxExemption: boolean;
    isConsumerCreditNote: boolean;
    maximumAmortizationPeriods: number;
    useCollateral: boolean;
    defaultCollateralDescription: string;
    warrantyRegistrationOffice: string;
};

export function useCreditProdutList(
    filters: ProductFiltersProps,
    refetchOnMount?: IRefetchOnMountModeGetQuery
) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const { token } = useIdentity();
    const [creditProductList, setCreditProduct] = useState<ProductOptionList[]>([]);
    const { filterValues, setFilterValues } = useFilterValues();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('CreditProduct', 'Read');

    const recordType = isEmpty(filterValues.recordType) ? 'CreditProduct' : filterValues.recordType;
    const listDataFilters = recordType === 'CreditProduct' && { ...filterValues.filters };
    const isAutocomplete: boolean = refetchOnMount === 'always';
    const filtersParams = isAutocomplete
        ? ({
              ...filters,
              size: 10,
              tenant: currentTenantId,
              isConsiderPostingDeadline: true,
          } as ProductFiltersProps)
        : filters; // it's a specific case
    const filtersComplete = { ...filtersParams, ...listDataFilters, tenant: currentTenantId };

    const queryContextCreditProduct = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ['products-list', filtersComplete],
        queryFn: async () => {
            startRequest();
            const resp = await getProductsList(filtersComplete, token);

            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const dataSuccess = data;
            const options = dataSuccess?.data.map((item) => ({
                label: item.name,
                value: item.id,
                amortizationType: item.amortizationType,
                instrumentType: item?.instrumentType,
                maximumInterestRate: item.maximumInterestRate,
                minimumInterestRate: item.minimumInterestRate,
                maximumPrincipalAmount: item.maximumPrincipalAmount,
                minimumPrincipalAmount: item.minimumPrincipalAmount,
                maximumAutomaticLiquidationValueInCents: item.maximumAutomaticLiquidationValueInCents,
                allowFinanceTaxExemption: item.allowFinanceTaxExemption,
                isConsumerCreditNote: item.isConsumerCreditNote,
                maximumAmortizationPeriods: item.maximumAmortizationPeriods,
                useCollateral: item.useCollateral,
                defaultCollateralDescription: item.defaultCollateralDescription,
                warrantyRegistrationOffice: item.warrantyRegistrationOffice,
                categoryName: item?.categoryName,
                name: item?.name,
                optionExtraKeyValues: [
                    { key: 'Parceiro', value: item.tenantDisplay },
                ],
            }));
            setCreditProduct(options);

            return dataSuccess;
        },
    });

    const creditProductAutoCompleteProps = {
        listOptions: creditProductList,
        loading: !hasReadPermission
            ? false
            : queryContextCreditProduct.isLoading || queryContextCreditProduct.isFetching,
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ searchString }, 'CreditProduct'),
    };

    return {
        ...queryContextCreditProduct,
        creditProductAutoCompleteProps,
    };
}

export function useCreditProductMutation(
    productId: string,
    onSuccess?: (data: CreditProductReadModel) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: CreditProductFormSchema) => {
            startRequest();
            const { data, status, statusText } = await editOrCreateCreditProduct(
                values,
                productId,
                token,
                currentTenantId
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateAsync, isLoading, error };
}

export function useGetModalityData(filters: ModalityListProps) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const [getModalityList, setGetModalityList] = useState<{ label: string; value: string }[]>([]);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const filtersComplete = { ...filters, descriptionModality: search };

    const queryContextModality = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        queryKey: ['modality-list', filtersComplete],
        queryFn: async (): Promise<GetListApiResponse<Modality>> => {
            startRequest();
            const resp = await getModalityListAsync(filtersComplete, token);

            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const dataSuccess = data as GetListApiResponseSuccess<Modality>;
            const options = dataSuccess.data.map((item) => ({
                label: `(${item.code || 'Não informado'}) - ${item.description}`,
                value: item.id,
                code: item.code,
            }));
            setGetModalityList(options);

            return dataSuccess;
        },
    });

    const modalityAutoCompleteProps = {
        listOptions: getModalityList,
        loading: queryContextModality.isLoading || queryContextModality.isFetching,
        onSearch: (searchString: string | undefined) => setSearch(searchString),
    };

    return {
        ...queryContextModality,
        modalityAutoCompleteProps,
    };
}

export function useGetSubModalityData(filters: SubModalityProps) {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { token } = useIdentity();
    const [getSubModalityList, setGetSubModalityList] = useState<
        { label: string; value: string }[]
    >([]);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const filtersComplete = { ...filters, descriptionSubModality: search };

    const queryContextSubModality = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        queryKey: ['submodality-list', filtersComplete],
        queryFn: async () => {
            if (!filtersComplete?.codeModalite) return;
            startRequest();
            const resp = await getSubModalityListAsync(filtersComplete, token);

            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const dataSuccess = data as GetListApiResponseSuccess<Modality>;
            const options = dataSuccess.data.map((item) => ({
                label: `(${item.code || 'Não informado'}) - ${item.description}`,
                value: item.id,
            }));
            setGetSubModalityList(options);

            return dataSuccess;
        },
    });

    const subModalityAutoCompleteProps = {
        listOptions: getSubModalityList,
        loading: queryContextSubModality.isFetching,
        onSearch: (searchString: string | undefined) => setSearch(searchString),
    };

    return {
        ...queryContextSubModality,
        subModalityAutoCompleteProps,
    };
}

export type TGetCosif = {
    label: string | undefined;
    value: string | undefined;
    externalAccountId: string | undefined;
};

export function useCosifData() {
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [getCosif, setGetCosif] = useState<TGetCosif[]>();

    const { token } = useIdentity();
    const { data, isLoading, error, refetch } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['cosif-data'],
        queryFn: async (): Promise<any> => {
            startRequest();
            const { data, status, statusText } = await getCosifAccountTransaction(token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const dataSucess = data ?? [];
            const cosif = dataSucess?.map((item) => {
                return {
                    label: item?.description,
                    value: item?.code,
                    externalAccountId: item?.externalAccountId,
                };
            });
            setGetCosif(cosif);

            return dataSucess ?? [];
        },
    });

    return { cosifData: data, isLoading, error, refetch, getCosif };
}
