import { Grid, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { CompleteIcon, DataTable, DeleteIcon, EditIcon } from '@uy3/web-components';
import { CommunicationSettingFiltersContainer } from 'components/DataTableFilters/CommunicationSettingFilters/CommunicationSettingFiltersContainer';
import { RowActions } from 'components/RowActions/RowActions';
import { GetListApiResponseSuccess, iconSx } from 'contexts/apiRequestContext';
import { formatBooleanValueCommunicationSetting } from 'helpers/formats/StringFormats';
import React from 'react';
import { useNavigate } from 'react-router';
import { ICommunicationSettingFull } from 'services/walletManagement/communicationSetting/communicationSetting.types';

type CommunicationSettingListProps = {
    queryData: GetListApiResponseSuccess<ICommunicationSettingFull>;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    handleAction: React.Dispatch<React.SetStateAction<{
        action: string;
        row?: ICommunicationSettingFull;
    } | undefined>>
}

export const CommunicationSettingList: React.FC<CommunicationSettingListProps> = ({ queryData, page, rowsPerPage, setPage, setRowsPerPage, handleAction }) => {
    const navigate = useNavigate();
     
    const columns: GridColDef[] = [
        {
            field: 'walletCode',
            headerName: 'Código do convênio',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'walletNumber',
            headerName: 'Número da carteira',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({value}) => !!value ? value : "N/D"
        },
        {
            field: 'name',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'daysToSendBillingBeforeDueDate',
            headerName: 'Dias para envio da cobranca',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({value}) => !!value ? `${value} dias` : 'N/D'
        },
        {
            field: 'notifyByEmail',
            headerName: 'Notificar por e-mail',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({value}) => formatBooleanValueCommunicationSetting(value)
        },
        {
            field: 'notifyBillingAfterSettlement',
            headerName: 'Notificar liquidação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({value}) => formatBooleanValueCommunicationSetting(value)
        },
        {
            field: 'notifyBillingUpdate',
            headerName: 'Notificar atualização',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({value}) => formatBooleanValueCommunicationSetting(value)
        },
        {
            field: 'resendBillingAfterOverDueDate',
            headerName: 'Reenviar após vencimento',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({value}) => formatBooleanValueCommunicationSetting(value)
        },
        {
            field: 'notifyBillingAfterOverDueDate',
            headerName: 'Notificar depois do vencimento',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({value}) => formatBooleanValueCommunicationSetting(value)
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                return (
                    <Stack direction={'row'} justifyContent="center" alignItems="center">
                        <RowActions
                            listButtons={[
                                {
                                    action: () => handleAction({action: "edit", row}),
                                    label: "Editar",
                                    disabled: false,
                                    hide: false,
                                    icon: <EditIcon sx={iconSx} /> 
                                }, 
                                {
                                    action: () => handleAction({action: "delete", row}),
                                    label: "Excluir",
                                    disabled: false,
                                    hide: false,
                                    icon: <DeleteIcon sx={iconSx} />
                                }, 
                                {
                                    action: () => navigate(`/gestao-carteira/cobrancas?communicationSettingId=${row?.id}`, { viewTransition: true}),
                                    label: "Ver cobranças",
                                    disabled: false,
                                    hide: false,
                                    icon: <CompleteIcon sx={iconSx} />
                                }, 
                            ]}
                        />
                    </Stack>
                );
            },
        },
    ];

    return (
        <DataTable
        filterComponent={{
            showFilters: true,
            componentFilter: (
                <Grid mb={-0.5}>
                    <CommunicationSettingFiltersContainer />
                </Grid>
            ),
        }}
            columns={columns}
            rowsPerPage={rowsPerPage}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            pageCount={queryData?.totalPages ?? 0}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    )
}
