import { Grid, Stack } from '@mui/material'
import { Button, CloseIcon, SaveIcon, Typography } from '@uy3/web-components'
import { Autocomplete, AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { useSelectColumns } from 'contexts/SelectColumnContexts';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type SelectedValuesProps = {
  cancelAndClearFilters: () => void;
  options: AutocompleteOptionList;
};

const theme = activeTheme();
const SelectColumns = ({ cancelAndClearFilters, options }: SelectedValuesProps) => {
  const { selectedColumns, columns, setSelectedColumns } = useSelectColumns();

  const handleChange = (_: any, options: any) => {
    let currentOptions = options?.map((option: any) => option?.value);
    setSelectedColumns(currentOptions)
  };

  const optionsColumnsSelected = selectedColumns
    ?.map((columnName: any) => {
      const column = columns.filter(c => c.field === columnName)[0]
      if (!column)
        return null
      return { label: column.headerName, value: column.field }
    })
    .filter(Boolean);

  return (
    <Stack>
      <Typography variant='xxl' >Colunas disponíves na tabela</Typography>
      <Typography variant='sm' mb={3}>Selecione as colunas que deseja ver dentro da tabela</Typography>
      <Autocomplete
        name="columns"
        multiple
        label="Colunas"
        value={optionsColumnsSelected}
        onChange={handleChange}
        listOptions={options?.listOptions}
        loading={options?.loading}     
      />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='right'
        mt={4}
        spacing={2}
      >
        <Grid>
          <Button onClick={cancelAndClearFilters} startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />} variant='outlined'>
            Cancelar e Limpar filtros
          </Button>
        </Grid>
        <Grid>
          <Button startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />} type='submit' variant='contained'>Salvar</Button>
        </Grid>
      </Stack>
    </Stack>
  )
}

export default SelectColumns
