import { useFilters } from 'contexts/filterContext';
import { FormProvider } from 'contexts/formContext';
import React, { useState } from 'react';
import { BillingFilters } from './BillingFilters';
import { billingFiltersSchema, defaultValuesBillingFilterSchema } from './BillingFiltersSchema';
import { FieldValues } from 'react-hook-form';
import { Toast } from '@uy3/web-components';
import { Grid } from '@mui/material';

export type BillingFiltersContainerProps = {
    filtersOptions?: string[];
}

export const BillingFiltersContainer: React.FC<BillingFiltersContainerProps> = ({filtersOptions = []}) => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();
    const [existsStatusFilter, setExistsStatusFilter] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams?.getAll('statusesValue');

    const handleOnSubmit = (values: FieldValues) => {
        const { optionFilter } = values;
        switch (optionFilter) {
            case 'statusesValue':
                if (status && status.includes(values[optionFilter])) {
                    setExistsStatusFilter(true);
                    return;
                }
                addFilter(optionFilter, values[optionFilter]);
                setExistsStatusFilter(false);
                break;
            case 'initialDueDate':
                const finalDate = new Date(values.finalDueDate)
                addFilter(optionFilter, values[optionFilter], finalDate);
                break;
            case 'initialSettlementDate':
                const finalSettlementDate = new Date(values.finalSettlementDate)
                addFilter(optionFilter, values[optionFilter], finalSettlementDate);
                break;
            case 'initialCreatedDate':
                const finalCreatedDate = new Date(values.finalCreatedDate); 
                addFilter(optionFilter, values[optionFilter], finalCreatedDate);
                break;
            default:
                addFilter(optionFilter, values[optionFilter]);
        }
    };

    const validationSchema = billingFiltersSchema();
    const defaultValues = defaultValuesBillingFilterSchema;

    return (
        <>
            <Grid container item>
                <Toast
                    severity="info"
                    title="O filtro já está aplicado na listagem abaixo."
                    onClose={() => setExistsStatusFilter(false)}
                    open={existsStatusFilter}
                />
            </Grid>

            <FormProvider
                defaultValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
            >
                <BillingFilters
                    filtersOptions={filtersOptions}
                    currentValues={filters}
                    removeFilterSelected={removeFilterByIndex}
                />
            </FormProvider>
        </>
    );
};
