import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { SpanCustom } from 'components/HelpPermission/HelpPermission';
import { validationInformation, formatCurrencyInCents, formatDate } from 'helpers';
import React, { ReactNode } from 'react';
import { IResumo } from 'services/zetra';

type TooltipConsignmentProps = {
    values: IResumo;
    currentIndex: number
};

interface IChildren {
    children: ReactNode;
}

export const TooltipConsignment: React.FC<TooltipConsignmentProps> = ({ values: resumo, currentIndex }) => {

    const ListItem = ({ children }: IChildren) => {
        return <Typography style={{ margin: '5px 0 ', fontSize: '14px' }}>{children}</Typography>;
    };

    const valorParcela = formatCurrencyInCents(resumo?.valorParcela);

    return (
        <Stack sx={{ margin: '5px 0' }} spacing={2}>
            <Typography variant="md" fontWeight="bold" textAlign="center">
                Informações / Detalhes
            </Typography>

            <ListItem>
                <SpanCustom>Situação: </SpanCustom>
                {validationInformation(resumo?.situacao)}
            </ListItem>
            <ListItem>
                <SpanCustom>Identificador: </SpanCustom>
                {validationInformation(resumo?.adeIdentificador)}
            </ListItem>

            <ListItem>
                <SpanCustom>Nº ADE: </SpanCustom>
                {resumo?.adeNumero}
            </ListItem>

            <ListItem>
                <SpanCustom>Código da Consignatária: </SpanCustom>
                {validationInformation(resumo?.consignatariaCodigo)}
            </ListItem>

            <ListItem>
                <SpanCustom>Consignatária: </SpanCustom>
                {validationInformation(resumo?.consignataria)}
            </ListItem>

            <ListItem>
                <SpanCustom>Serviço: </SpanCustom>
                {validationInformation(resumo?.servico)}
            </ListItem>

            <ListItem>
                <SpanCustom>Verba: </SpanCustom>
                {validationInformation(resumo?.codVerba)}
            </ListItem>

            <ListItem>
                <SpanCustom>Data de reserva: </SpanCustom>
                {formatDate(resumo?.dataReserva)}
            </ListItem>

            <ListItem>
                <SpanCustom>Prazo: </SpanCustom>
                {resumo?.prazo}
            </ListItem>

            <ListItem>
                <SpanCustom>Pagas: </SpanCustom>
                {resumo?.pagas}
            </ListItem>

            <ListItem>
                <SpanCustom>Valor da parcela: </SpanCustom>
                {validationInformation(valorParcela)}
            </ListItem>
        </Stack>
    );
};
