/* eslint-disable no-self-assign */
import {
    GetListApiResponse,
    GetListApiResponseSuccess,
    PaginationSelfType,
} from 'contexts/apiRequestContext';
import { creditAxiosApi } from 'services/axiosApi/axiosApi';
import { NaturalPersonReadModel, IPersonBeneficiariesType } from './types/naturalPersonReadModel';
import { mapDataToModelInstance, toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { NaturalPersonSchema } from 'pages/NaturalPerson/NaturalPersonForm/NaturalPersonSchema';
import { NaturalPersonCreateModel } from './types/naturalPersonCreateModel';
import { NaturalPersonUpdateModel } from './types/naturalPersonUpdateModel';
import { NaturalPersonFiltersProps } from './types/generics';
import { ReserveTokenCreateModel } from './types/WarrantyRegistrationOfficeToken';

const url = '/NaturalPerson';
export async function getNaturalPersonById(personId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get<NaturalPersonReadModel>(`${url}/${personId}`, config);
}

export const getBeneficiariesList = async (accountId: string, token: string, filters: any) => {
    filters.orderBy =
        filters?.orderByField !== undefined
            ? filters?.orderByField + '_' + filters?.orderByDirection.toUpperCase()
            : undefined;
    const params = filters;

    delete params.orderByField;
    delete params.orderByDirection;

    const urlFull = `/BankAccount/${accountId}/Beneficiary`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params,
    };

    return creditAxiosApi.get<GetListApiResponseSuccess<IPersonBeneficiariesType[]> & PaginationSelfType>(urlFull, config);
};


export async function getNaturalPersonsList(filters: NaturalPersonFiltersProps, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters,
    };

    return await creditAxiosApi.get<GetListApiResponse<NaturalPersonReadModel>>(url, config);
}

function cleanNumber(value?: string | null): string | undefined {
    const onlyNumbers = /\D/g;
    return value?.replace(onlyNumbers, '');
}

function hasRegistrationNumber(data: any): data is { registrationNumber: string } {
    return 'registrationNumber' in data;
}

type NaturalPersonTypeModel = NaturalPersonCreateModel | NaturalPersonUpdateModel;
export async function editOrCreateNaturalPerson(
    data: NaturalPersonSchema,
    personId: string,
    token = '',
    tenant?: string
) {
    const isCreating = personId === 'nova';
    const urlFull = isCreating ? url : `${url}/${personId}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    const NaturalPersonCreateOrUpdate = isCreating
        ? NaturalPersonCreateModel
        : NaturalPersonUpdateModel;

    const naturalPersonDataModel = toDataModel<NaturalPersonTypeModel>(
        data,
        NaturalPersonCreateOrUpdate
    );

    if (naturalPersonDataModel?.bankAccounts) {
        naturalPersonDataModel.bankAccounts.forEach((bankAccount) => {
            bankAccount.pixKeyTypeValue === 'AgencyAndAccount'
                ? (bankAccount.keyPix = null)
                : (bankAccount.keyPix = bankAccount?.keyPix);
            if (bankAccount.pixKeyTypeValue === 'Phone') {
                if (bankAccount?.keyPix?.startsWith('+55')) {
                    return bankAccount.keyPix;
                } else {
                    return (bankAccount.keyPix = '+55' + cleanNumber(bankAccount?.keyPix));
                }
            }
            return bankAccount;
        });
    }

    let cleanedStructureData: { [key: string]: string | undefined } = {
        documentNumber: cleanNumber(naturalPersonDataModel?.documentNumber),
        workplaceCompanyRegistrationNumber: cleanNumber(
            naturalPersonDataModel?.workplaceCompanyRegistrationNumber
        ),
        spouseDocumentNumber: cleanNumber(naturalPersonDataModel?.spouseDocumentNumber),
        spouseWorkplace: cleanNumber(naturalPersonDataModel?.spouseWorkplace),
        phone: cleanNumber(naturalPersonDataModel?.phone),
        phone2: cleanNumber(naturalPersonDataModel?.phone2),
        commercialPhone: cleanNumber(naturalPersonDataModel?.commercialPhone),
        commercialPhone2: cleanNumber(naturalPersonDataModel?.commercialPhone2),
        spouseCommercialPhone: cleanNumber(naturalPersonDataModel?.spouseCommercialPhone),
    };

    // Se o naturalPersonDataModel tiver 'registrationNumber', adiciona ao objeto cleanedStructureData
    if (hasRegistrationNumber(naturalPersonDataModel)) {
        cleanedStructureData.registrationNumber = cleanNumber(
            naturalPersonDataModel?.registrationNumber
        );
    }

    const payload = {
        ...naturalPersonDataModel,
        ...cleanedStructureData,
    };

    const axiosFunction = isCreating ? creditAxiosApi.post : creditAxiosApi.put;

    return await axiosFunction<NaturalPersonReadModel>(urlFull, payload, axiosConfig);
}

export async function deleteNaturalPersonById(personId: string, token = '') {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.delete(`${url}/${personId}`, config);
}

export async function createNaturalPerson(
    data: NaturalPersonSchema,
    tenant: string,
    token: string
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    const payload = toDataModel(data, NaturalPersonCreateModel);
    return await creditAxiosApi.post<NaturalPersonReadModel>(url, payload, config);
}

export async function postRequestNaturalPersonDatasetReserveToken(
    personId: string,
    data: ReserveTokenCreateModel[],
    token: string
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const payload = mapDataToModelInstance(data, 'ReserveTokenCreateModel', { ReserveTokenCreateModel }) as ReserveTokenCreateModel[];
    return await creditAxiosApi.post(
        `${url}/${personId}/dataset/ReserveToken/reserveToken`,
        payload,
        config
    );
}

export async function postRegulateRegistrationNumber(personId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(`${url}/${personId}/EnableRegistrationNumber`, {}, config);
}

export async function postOpenBankAccountByPersonId(personId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`${url}/${personId}/OpenBankAccount`, {}, config);
}
