import { FieldValues } from 'react-hook-form';
import { creditAxiosApi } from 'services/axiosApi/axiosApi';
import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { BankAccountCreateModel, BankAccountUpdateModel } from 'services/accounts/class/BankAccountModel';
import { PersonFiltersProps } from './types';

var url = `/Person`;
export const getPersonsList = async (filters: PersonFiltersProps, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters,
    };

    return await creditAxiosApi.get(url, config);
};

export const getPersonsByRegistrationNumberAsync = async (
    registrationNumber: string,
    token: string
) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const endpoint = `${url}/${registrationNumber}/QueryBasicData`;
    return await creditAxiosApi.get(endpoint, config);
};

export async function saveOrUpdateBankAccountAsync(
    recordType: string,
    data: FieldValues,
    personId: string,
    token: string,
    bankAccountId?: string
) {
    const isCreating = !bankAccountId;

    const urlFull = isCreating
        ? `${recordType}/${personId}/BankAccount`
        : `${recordType}/${personId}/BankAccount/${bankAccountId}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const BankAccountCreateOrUpdate = isCreating ? BankAccountCreateModel : BankAccountUpdateModel;
    const bankAccountDataModel = toDataModel(data, BankAccountCreateOrUpdate);
    
    const payload = isCreating ? [bankAccountDataModel] : bankAccountDataModel;
    const axiosFunction = isCreating ? creditAxiosApi.post : creditAxiosApi.put;

    return await axiosFunction<Array<string> | undefined>(urlFull, payload, axiosConfig);
}

export async function deletePersonsAccountById(
    recordType: string,
    personId: string,
    bankAccountId: string,
    token: string
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.delete(
        `${recordType}/${personId}/BankAccount/${bankAccountId}`,
        config
    );
}
