import { Stack } from '@mui/material'
import { Button, CloseIcon, SaveIcon, Typography } from '@uy3/web-components'
import { ApiResponseError, handleOnError, iconSx, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useOpenBankAccountByNaturalPersonMutate } from 'contexts/naturalPersonContext';
import { formatBankAccountForOption } from 'helpers';
import React from 'react'
import { FieldValues } from 'react-hook-form';
import { activeTheme } from 'services/theme'
import { IBankAccountReadModel } from 'utils';

const theme = activeTheme();

type OpenBankAccountProps = {
    onClose: () => void;
    personId: string;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    personDisplay: string;
    refetchPersonListBankAccount: () => void
    handleCloseAll: () => void
}

export const OpenBankAccount = ({ onClose, personId, setToast, personDisplay, refetchPersonListBankAccount, handleCloseAll }: OpenBankAccountProps) => {
    const { setValue } = useFormContext();

    const onSuccess = (response: FieldValues) => {
        showSuccessToast(
            "Sucesso ao reservar número de conta", 
            "A sua conta está disponível para ser adicionada como conta de liquidação", 
            setToast
        );
        refetchPersonListBankAccount();
        handleCloseAll(); 

        if (response !== null) {
            setValue('bankAccountId', response?.id);
            setValue('bankAccountIdDisplay', formatBankAccountForOption(response as IBankAccountReadModel));
        }
    };

    const onError = (error: ApiResponseError) => handleOnError(error, setToast);

    const { mutateAsync, isLoading } = useOpenBankAccountByNaturalPersonMutate((data) => onSuccess(data), onError);

    return (
        <Stack spacing={2}>
            <Typography fontSize='16px'>Realizar abertura de conta transitória para <b>{personDisplay}</b></Typography>

            <Stack direction="row" justifyContent="flex" alignItems="center" spacing={2} pt={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    fullWidth
                >
                    Fechar
                </Button>

                <Button
                    variant="contained"
                    name="btn-continuar"
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    type="submit"
                    onClick={() => mutateAsync(personId)}
                    fullWidth
                    disabled={isLoading}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
