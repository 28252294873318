/* eslint-disable react-hooks/exhaustive-deps */
import { Drawer } from '@uy3/web-components';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { useEnumContext } from 'contexts/enumContext';
import { Error } from 'components/Errors/Error';
import InvolvedList from './InvolvedList';
import { InvolvedForm } from './InvolvedForm';
import { defaultValuesInvolvedForm, validationSchemaInvolvedForm } from './InvolvedSchema';
import { usePersonsList } from 'contexts/personContext';
import { useLegalPersonData } from 'contexts/legalPersonContext';
import { ToastType, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useCreditProductData } from 'contexts/creditProductContext';
import { RelatedPerson, RelatedPersonFull } from 'services/creditProduct';
import { useParams } from 'react-router';
import { getLegalPersonById } from 'services/accounts/legalPerson/LegalPerson';
import { useIdentity } from 'contexts/identityContext';
import Toast from 'components/Toast/Toast';
import { isEmpty } from 'lodash';
import { useUserPermissionData } from 'contexts/userContext';
import { hasCustomPermission, removeDuplicateData, assignToRelatedPersons, importRelatedPersonByPersonId } from 'helpers';

type InvolvedTabContainerProps = {
    fieldName: string;
    enableImportSignatories?: boolean;
};

interface Person {
    discriminator: string | undefined;
    id: string | undefined;
}

type ImportedSignatoriesProps = {
    label: string;
    value: string;
};

export const InvolvedTabContainer = ({ fieldName, enableImportSignatories = false }: InvolvedTabContainerProps) => {
    const { id } = useParams();
    const { token } = useIdentity();
    const { data: typeOfRelationship } = useEnumContext({ enumName: 'TypeOfRelationship', size: 50 });
    const { data: signatureType } = useEnumContext({ enumName: 'SignatureType' });
    const { data: signatureValidation } = useEnumContext({ enumName: 'SignatureValidation', size: 50 });
    const [importedSignatories, setImportedSignatories] = useState<ImportedSignatoriesProps[]>([]);
    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 }, 'always');
    const [selectedInvolvedIndex, setSelectedInvolvedIndex] = useState<number | undefined>();
    const [selectedPerson, setSelectedPerson] = useState<Person | undefined>();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { setValue, watch } = useFormContext();
    const { submitError } = useApiRequest();
    const [hasSignatories, setHasSignatories] = useState(false);
    const { hasPermission } = useUserPermissionData();
    const permissionCustom = (typePermission: string) => hasCustomPermission('CreditProduct', typePermission, hasPermission);


    const [personId, productId, personDiscriminator] = watch([
        'personId',
        'productId',
        'personDiscriminator'
    ]);

    const isNewCreditNote = id === 'nova';
    const isSelectedLegalPerson =
        selectedPerson?.id && selectedPerson?.discriminator === 'LegalPerson';
    const isDiscriminatorLegalPersonForm = personDiscriminator === 'LegalPerson';

    const legalPersonId = isSelectedLegalPerson
        ? selectedPerson.id
        : isDiscriminatorLegalPersonForm
            ? personId
            : 'novo';

    const involveds = watch(fieldName) ?? [];
    let isRelatedPersonCreditNote = fieldName === 'relatedPersonCreditNote';

    const { creditProductData, creditProductStatus } = useCreditProductData(productId);
    const { legalPersonData } = useLegalPersonData(legalPersonId);

    const relatedPersonCreditNote: RelatedPersonFull[] = watch('relatedPersonCreditNote') ?? [];

    const processRelatedPersonsForSignatories = () => {
        let { personIdDisplay } = relatedPersonCreditNote[selectedInvolvedIndex ?? 0];

        const newSignature = relatedPersonCreditNote.filter((item: RelatedPersonFull) => {
            const { relatedToIdDisplay } = item;
            const isRelatedToIdMatch = (relatedToIdDisplay === personIdDisplay || personIdDisplay.includes(relatedToIdDisplay));

            if (!isEmpty(item.relatedToIdDisplay) && isRelatedToIdMatch) {
                setHasSignatories(true);
                return true;
            }
            return false;
        }).map((item: RelatedPersonFull) => ({
            label: item?.personIdDisplay,
            value: item?.personId,
        }));

        setImportedSignatories(newSignature);
    };

    const processLegalPersonForSignatories = () => {
        const signature = legalPersonData?.relatedPerson?.length > 0
        setHasSignatories(signature);
    };

    useEffect(() => {

        if (relatedPersonCreditNote.length > 0 && enableImportSignatories) {
            let { discriminator, personDiscriminator } = relatedPersonCreditNote[selectedInvolvedIndex ?? 0];
            if ((discriminator || personDiscriminator) === 'LegalPerson') {
                processRelatedPersonsForSignatories();
            }
        }

        if (legalPersonData && selectedInvolvedIndex === undefined && enableImportSignatories) {
            processLegalPersonForSignatories();
        }

    }, [relatedPersonCreditNote, legalPersonData, selectedInvolvedIndex, enableImportSignatories]);

    const saveRelatedPersonAutomatic = (personId: string, currentRelatedPerson: RelatedPerson[]) => {
        getLegalPersonById(personId, token!).then(({ data: { relatedPerson } }) => {
            const { allRelatedPersons } = importRelatedPersonByPersonId({
                currentRelatedPerson,
                personId,
                relatedPersonToImport: relatedPerson,
                setValue,
                watch,
            });
            if (allRelatedPersons.length > 0) {
                setValue(fieldName, []);
                const involvedFiltered = involveds?.filter(
                    (item: RelatedPerson) => item.productId === personId
                );
                const relatedPerson = [
                    ...involvedFiltered,
                    ...currentRelatedPerson,
                    ...allRelatedPersons
                ];
                setValue(
                    fieldName,
                    assignToRelatedPersons(
                        removeDuplicateData(relatedPerson, ['personId', 'typeOfRelationship']),
                        productId
                    )
                );
            }
        });
    };

    useMemo(() => {
        if (isRelatedPersonCreditNote && isNewCreditNote) {
            const relatedPerson: RelatedPerson[] = creditProductData?.relatedPerson ?? [];
            const relatedPersonFiltered = relatedPerson.filter(
                (related) => related.typeOfRelationship !== 'Beneficiary'
            );
            if (!!productId && !!creditProductData) {
                const hasCreditProductIdEqual = involveds?.every(
                    (person: RelatedPerson) => person?.productId === productId
                );
                if (involveds?.length && hasCreditProductIdEqual) return;
                const relatedPersonToSave = assignToRelatedPersons(
                    removeDuplicateData(relatedPersonFiltered, ['personId', 'typeOfRelationship']),
                    productId
                );
                setValue(fieldName, relatedPersonToSave);
                relatedPersonFiltered.forEach(({ personId, discriminator }) => {
                    if (discriminator === 'LegalPerson') {
                        saveRelatedPersonAutomatic(personId, relatedPersonFiltered);
                    }
                });
            }
        }
    }, [creditProductStatus]);

    const recordStatus = watch('status') ?? '';
    const readOnly = !['Draft', 'Error', 'Revision', 'Disapproved', ''].includes(recordStatus);

    if (submitError) {
        return <Error error={submitError} />;
    }

    const setNewRelatedPerson = (values: any) => {
        const personsToImport = [...(values.personsToImport ?? [])];
        values.personsToImport = [];
        let newRelatedPerson = involveds !== undefined ? [...involveds] : [];
        if (selectedInvolvedIndex === undefined) {
            var duplicatedInvolvedIndex = newRelatedPerson.findIndex(
                (item: any) =>
                    item.personId === values.personId &&
                    item.relatedToId === values.relatedToId &&
                    item.typeOfRelationship === values.typeOfRelationship
            );
            if (duplicatedInvolvedIndex > -1) {
                newRelatedPerson[duplicatedInvolvedIndex] = values;
            } else {
                newRelatedPerson?.push(values);
            }
        } else newRelatedPerson[selectedInvolvedIndex] = values;

        if (fieldName === 'relatedPersonCreditNote')
            setValue('relatedPersonCreditNote', assignToRelatedPersons(newRelatedPerson, productId));
        else setValue('relatedPerson', newRelatedPerson);

        if (personsToImport?.length > 0) {
            let signatories = legalPersonData?.relatedPerson
                .filter((p: any) => personsToImport.includes(p.personId))
                .map((item: any) => {
                    return {
                        relatedToId: legalPersonData?.id,
                        relatedToIdDisplay: legalPersonData?.name,
                        ...item,
                    };
                });
            newRelatedPerson.push(...signatories);
            setValue(fieldName, assignToRelatedPersons(newRelatedPerson, productId));
        }
        const title = 'Lista de envolvidos atualizada com sucesso!';
        const description = 'Ótimo! agora você pode adicionar um novo envolvido.'
        showSuccessToast(title, description, setToast);
        closeDrawer();
    };

    const closeDrawer = () => {
        setSelectedInvolvedIndex(undefined);
        setOpenDrawer(false);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    function onDelete(index: number) {
        let rowData = [...involveds];
        rowData?.splice(index, 1);
        setValue(fieldName, rowData);
        const title = `Sucesso ao excluir o envolvido!`;
        const description = 'Ótimo! agora você pode adicionar um novo envolvido.';
        showSuccessToast(title, description, setToast);
    }

    const validationSchemaInvolved = validationSchemaInvolvedForm(fieldName, creditProductData);
    const defaultFormValue =
        selectedInvolvedIndex !== undefined
            ? involveds[selectedInvolvedIndex!]
            : defaultValuesInvolvedForm;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <InvolvedList
                readOnly={readOnly}
                name={fieldName}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                page={page}
                setOpenDrawer={setOpenDrawer}
                setSelectedInvolvedIndex={(rowNumber: number | undefined) =>
                    setSelectedInvolvedIndex(rowNumber ?? involveds?.length)
                }
                onDelete={onDelete}
                setHasSignatories={setHasSignatories}
            />
            <Drawer
                anchor="right"
                title="Envolvido"
                description="Confira as informações antes de adicionar o envolvido"
                open={selectedInvolvedIndex !== undefined || openDrawer}
                onClose={closeDrawer}
            >
                <FormProvider
                    validationSchema={validationSchemaInvolved}
                    defaultValues={defaultFormValue}
                    onSubmit={setNewRelatedPerson}
                >
                    <InvolvedForm
                        creditProductData={creditProductData!}
                        importedSignatories={importedSignatories}
                        setImportedSignatories={setImportedSignatories}
                        setSelectedPerson={setSelectedPerson}
                        legalPersonData={legalPersonData ?? []}
                        name={fieldName}
                        typeOfRelationship={typeOfRelationship ?? []}
                        signatureType={signatureType ?? []}
                        signatureValidation={signatureValidation ?? []}
                        onClose={closeDrawer}
                        personList={personAutoCompleteProps}
                        hasSignatories={hasSignatories}
                        hasPermissionIncludeBeneficiaryProduct={permissionCustom('IncludeBeneficiaryProduct')}
                    />
                </FormProvider>
            </Drawer>
        </>
    );
};
