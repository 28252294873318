import { Button, Drawer } from '@uy3/web-components';
import DrawerAddNaturalPersonForm from 'pages/NaturalPerson/DrawerForm/DrawerAddNaturalPersonForm';
import React, { useState } from 'react';
import RegistrationType from './RegistrationType/RegistrationType';
import Toast from 'components/Toast/Toast';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import DrawerAddLegalPersonForm from 'pages/LegalPerson/DrawerForm/DrawerAddLegalPersonForm';
import { useFormContext } from 'contexts/formContext';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useUserPermissionData } from 'contexts/userContext';
import { useAppConfig } from 'contexts/appConfig';

interface DrawerAddPersonProps {
    open: boolean;
    onClose: () => void;
    isAddBankAccount?: boolean;
    changeAccount?: boolean;
    isAddPerson: boolean;
    toggleDrawer?: boolean;
    onSuccessGeneric?: (values: FieldValues) => void;
    handleOpenBankAccount?: () => void;
}

type PersonType = 'LegalPerson' | 'NaturalPerson';

export default function DrawerAddPerson({
    onClose,
    open,
    isAddBankAccount = false,
    changeAccount,
    isAddPerson,
    toggleDrawer,
    onSuccessGeneric,
    handleOpenBankAccount,
}: DrawerAddPersonProps) {
    const { hasPermission } = useUserPermissionData();
    const [currentStep, setCurrentStep] = useState(0);
    const [nextStep, setNextStep] = useState(false);
    const [personType, setPersonType] = useState<PersonType>();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch, setValue } = useFormContext();
    const { product } = useCreditNoteFormContext();
    const { appConfig } = useAppConfig();
    const useCDC = product?.isConsumerCreditNote;

    const [
        beneficiaryId,
        beneficiaryDisplay,
        beneficiaryDiscriminator,
        personId,
        personDisplay,
        personDiscriminator,
        liquidationType
    ] = watch([
        'beneficiaryId',
        'beneficiaryDisplay',
        'beneficiaryDiscriminator',
        'personId',
        'personDisplay',
        'personDiscriminator',
        'liquidationType'
    ]);

    const isBeneficiaryLegalPerson = beneficiaryDiscriminator === 'LegalPerson';
    const isBeneficiaryNaturalPerson = beneficiaryDiscriminator === 'NaturalPerson';
    const isLegalPerson = personDiscriminator === 'LegalPerson';
    const isNaturalPerson = personDiscriminator === 'NaturalPerson';

    // not is changeAccount("Conta para troco") and beneficiaryDisplay is not null
    const displayName = (!changeAccount && beneficiaryDisplay) || personDisplay;

    function getLegalPersonId() {
        if (useCDC && !changeAccount && isBeneficiaryLegalPerson && !isAddPerson) {
            return beneficiaryId;
        } else if (isLegalPerson && !isAddPerson) {
            return personId;
        }
        return 'novo';
    }
    const legalPersonId = getLegalPersonId();

    function getNaturalPersonId() {
        if (useCDC && !changeAccount && isBeneficiaryNaturalPerson && !isAddPerson) {
            return beneficiaryId;
        } else if (isNaturalPerson && !isAddPerson) {
            return personId;
        }
        return 'nova';
    }
    const naturalPersonId = getNaturalPersonId();

    const handleNextStep = () => {
        if (nextStep) setCurrentStep((prev) => prev + 1);
    };

    const handlePrevStep = () => {
        if (nextStep) setCurrentStep((prev) => prev - 1);
    };

    const handleClose = () => {
        onClose();
        setCurrentStep(0);
        setPersonType(undefined);
    };

    function shouldAddLegalPerson() {
        const isBeneficiaryLegalPersonCondition = useCDC && isBeneficiaryLegalPerson && !changeAccount && !isAddPerson;
        const isChangeAccountLegalPersonCondition = changeAccount && isLegalPerson && !personType;
        const personTypeCondition = (!useCDC && isAddBankAccount && isLegalPerson) || personType === 'LegalPerson';

        return (!!isBeneficiaryLegalPersonCondition || !!isChangeAccountLegalPersonCondition || personTypeCondition
        );
    }

    const addLegalPerson = shouldAddLegalPerson();

    function shouldAddNaturalPerson() {
        const isBeneficiaryNaturalPersonCondition = useCDC && isBeneficiaryNaturalPerson && !changeAccount && !isAddPerson;
        const isChangeAccountNaturalPersonCondition = changeAccount && isNaturalPerson && !personType;
        const isAddBankAccountCondition = (!useCDC && isAddBankAccount && isNaturalPerson) || !!personType

        return isBeneficiaryNaturalPersonCondition || isChangeAccountNaturalPersonCondition || isAddBankAccountCondition

    }
    const addNaturalPerson = shouldAddNaturalPerson();

    const location = useLocation();
    const isBankAccountRequest = location.pathname.includes('/contas-digitais/solicitacoes');

    const drawerTitle = {
        NaturalPerson: 'Cadastrar pessoa física',
        LegalPerson: 'Cadastrar pessoa jurídica',
        BankAccount: 'Adicionar dados bancários',
        Borrower: 'Adicionar tomador',
        owner: 'Adicionar titular',
    };

    const type = isAddBankAccount ? 'BankAccount' : isBankAccountRequest ? "owner" : personType || 'Borrower';
    const title = drawerTitle[type];

    const showActionToOpenBankAccount = () => {
        if (
            liquidationType === 'EletronicTransfer' &&
            hasPermission("NaturalPerson", "Create") &&
            !!personId &&
            (!!personDiscriminator && personDiscriminator === "NaturalPerson") && 
            appConfig.ENABLE_RESERVE_BANKACCOUNT_NUMBER === 1
        ) {
            return true
        }

        return false;
    }


    return (
        <React.Fragment>
            <Toast toast={toast} setToast={setToast} />
            <Drawer
                toggleDrawer={toggleDrawer}
                open={open || isAddBankAccount}
                title={title}
                description="Confira as informações antes de criar um novo cadastro"
                anchor="right"
            >
                {currentStep === 0 && !isAddBankAccount && (
                    <RegistrationType {...{ setPersonType, personType, setNextStep }} />
                )}
                {addLegalPerson ? (
                    <DrawerAddLegalPersonForm
                        {...{
                            currentStep,
                            setNextStep,
                            isAddBankAccount,
                            onClose,
                            handleNextStep,
                            handlePrevStep,
                            handleClose,
                            setToast,
                            displayName,
                            legalPersonId,
                            setValue,
                            onSuccessGeneric
                        }}
                    />
                ) : (
                    addNaturalPerson && (
                        <DrawerAddNaturalPersonForm
                            {...{
                                currentStep,
                                setNextStep,
                                isAddBankAccount,
                                onClose,
                                handleNextStep,
                                handlePrevStep,
                                handleClose,
                                setToast,
                                displayName,
                                naturalPersonId,
                                setValue,
                                onSuccessGeneric,
                                isBankAccountRequest,
                                handleOpenBankAccount: () => handleOpenBankAccount && handleOpenBankAccount(),
                                showActionToOpenBankAccount: showActionToOpenBankAccount()
                            }}
                        />
                    )
                )}

                {!personType && !isAddBankAccount && (
                    <Grid container justifyContent="flex-end">
                        <Button onClick={handleClose} variant="outlined">
                            Cancelar
                        </Button>
                    </Grid>
                )}
            </Drawer>
        </React.Fragment>
    );
}
